@font-face {
  font-family: tablet_gothic_compressed;
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet-gothic-compressed-bold-18-2.eot");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet-gothic-compressed-bold-18-2.eot?#iefix") format("embedded-opentype");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet-gothic-compressed-bold-18-2.woff") format("woff");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet-gothic-compressed-bold-18-2.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "tablet_gothic_condensed", arial, Helvetica, sans-serif;
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot?#iefix") format("embedded-opentype");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.woff") format("woff");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.ttf?v=3") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: tablet_gothic_condensed_extra;
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-extrabold-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-extrabold-webfont.eot?#iefix") format("embedded-opentype");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-extrabold-webfont.woff") format("woff");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-extrabold-webfont.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  color: #16212d;
  overflow-anchor: none;
}

a {
  text-decoration: none;
  transition: all .3s;
}

a:hover {
  text-decoration: underline;
}

div {
  vertical-align: baseline;
  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

.sectionHeader {
  border-top: 4px solid red;
  margin-top: 50px;
  margin-bottom: 0;
  padding: 10px 0;
  position: relative;
  overflow: visible;
}

.banner_frantiska {
  float: left;
  margin: 10px 0;
  position: relative;
}

.banner_frantiska .imageWrapper {
  width: 620px;
  height: 126px;
}

.banner_zpravy {
  float: left;
  margin: 10px 0;
  position: relative;
}

#sticky-bottom-right-absolute > div {
  margin-top: 20px;
  float: left !important;
  position: relative !important;
  bottom: auto !important;
}

#article a {
  text-decoration: underline;
  transition: all .3s;
}

#article a:hover {
  text-decoration: none;
}

.container.whitecolor h3.title.bg {
  background: #fff !important;
}

.container.tvblack h3.title.bg {
  background: #16212d !important;
}

.container.pureBlack h3.title.bg {
  background: #000 !important;
}

.container.tvblacklighter h3.title.bg {
  background: #232b3a !important;
}

.container.redChristmas h3.title.bg {
  background: #b00 !important;
}

.container.pinkChristmas h3.title.bg {
  background: #ff8a8a !important;
}

.container.default h3.title.bg {
  background: #000 !important;
}

.container.grey h3.title.bg {
  background: #c9d3df !important;
}

.container.system h3.title.bg {
  background: red !important;
}

.container.colorHoverMenu h3.title.bg {
  background: #540101 !important;
}

.container.auto h3.title.bg, .container.pro-zeny h3.title.bg {
  background: red !important;
}

.container.sport h3.title.bg {
  background: #91c837 !important;
}

.container.svet h3.title.bg {
  background: #6e78dc !important;
}

.container.tvbox h3.title.bg {
  background: #fff !important;
}

.container.celebrity h3.title.bg {
  background: red !important;
}

.container.greyLight h3.title.bg {
  background: #c0ced5 !important;
}

.container.grey-comments h3.title.bg {
  background: #98a3af !important;
}

.container.bleskRed h3.title.bg, .container.colorTesty h3.title.bg {
  background: red !important;
}

.container.heliotrope h3.title.bg {
  background: #b469f5 !important;
}

.container.chetwode h3.title.bg {
  background: #083e89 !important;
}

.container.robinblue h3.title.bg {
  background: #00c8be !important;
}

.container.hotpink h3.title.bg {
  background: #fe60b2 !important;
}

.container.tyrkys h3.title.bg {
  background: #2dccd3 !important;
}

.container.saffron h3.title.bg {
  background: #f7b135 !important;
}

.container.jade h3.title.bg {
  background: #00b373 !important;
}

.container.scooter h3.title.bg {
  background: #3cc3dc !important;
}

.container.dodger h3.title.bg {
  background: #23aaff !important;
}

.container.domino h3.title.bg {
  background: #ff5a00 !important;
}

.container.atlantis h3.title.bg {
  background: #91c837 !important;
}

.container.homeorange h3.title.bg {
  background: #ff7300 !important;
}

.container.commercial h3.title.bg {
  background: #f1e9c1 !important;
}

.whitecolor .category-title, .whitecolor a.color, .whitecolor .cat-name, .whitecolor a.hoverColor:hover, .whitecolor .article-container:hover, .whitecolor .article-container:hover a.hoverColor {
  color: #fff !important;
}

.whitecolor .sectionHeader {
  border-top-color: #fff !important;
}

.whitecolor .alternative a.color {
  color: #fff !important;
}

.whitecolor .bg {
  background-color: #fff !important;
}

.tvblack .category-title, .tvblack a.color, .tvblack .cat-name, .tvblack a.hoverColor:hover, .tvblack .article-container:hover, .tvblack .article-container:hover a.hoverColor {
  color: #16212d !important;
}

.tvblack .sectionHeader {
  border-top-color: #16212d !important;
}

.tvblack .alternative a.color {
  color: #fff !important;
}

.tvblack .bg {
  background-color: #16212d !important;
}

.pureBlack .category-title, .pureBlack a.color, .pureBlack .cat-name, .pureBlack a.hoverColor:hover, .pureBlack .article-container:hover, .pureBlack .article-container:hover a.hoverColor {
  color: #000 !important;
}

.pureBlack .sectionHeader {
  border-top-color: #000 !important;
}

.pureBlack .alternative a.color {
  color: #fff !important;
}

.pureBlack .bg {
  background-color: #000 !important;
}

.tvblacklighter .category-title, .tvblacklighter a.color, .tvblacklighter .cat-name, .tvblacklighter a.hoverColor:hover, .tvblacklighter .article-container:hover, .tvblacklighter .article-container:hover a.hoverColor {
  color: #232b3a !important;
}

.tvblacklighter .sectionHeader {
  border-top-color: #232b3a !important;
}

.tvblacklighter .alternative a.color {
  color: #fff !important;
}

.tvblacklighter .bg {
  background-color: #232b3a !important;
}

.redChristmas .category-title, .redChristmas a.color, .redChristmas .cat-name, .redChristmas a.hoverColor:hover, .redChristmas .article-container:hover, .redChristmas .article-container:hover a.hoverColor {
  color: #b00 !important;
}

.redChristmas .sectionHeader {
  border-top-color: #b00 !important;
}

.redChristmas .alternative a.color {
  color: #fff !important;
}

.redChristmas .bg {
  background-color: #b00 !important;
}

.pinkChristmas .category-title, .pinkChristmas a.color, .pinkChristmas .cat-name, .pinkChristmas a.hoverColor:hover, .pinkChristmas .article-container:hover, .pinkChristmas .article-container:hover a.hoverColor {
  color: #ff8a8a !important;
}

.pinkChristmas .sectionHeader {
  border-top-color: #ff8a8a !important;
}

.pinkChristmas .alternative a.color {
  color: #fff !important;
}

.pinkChristmas .bg {
  background-color: #ff8a8a !important;
}

.default .category-title, .default a.color, .default .cat-name, .default a.hoverColor:hover, .default .article-container:hover, .default .article-container:hover a.hoverColor {
  color: #000 !important;
}

.default .sectionHeader {
  border-top-color: #000 !important;
}

.default .alternative a.color {
  color: #fff !important;
}

.default .bg {
  background-color: #000 !important;
}

.grey .category-title, .grey a.color, .grey .cat-name, .grey a.hoverColor:hover, .grey .article-container:hover, .grey .article-container:hover a.hoverColor {
  color: #c9d3df !important;
}

.grey .sectionHeader {
  border-top-color: #c9d3df !important;
}

.grey .alternative a.color {
  color: #fff !important;
}

.grey .bg {
  background-color: #c9d3df !important;
}

.system .category-title, .system a.color, .system .cat-name, .system a.hoverColor:hover, .system .article-container:hover, .system .article-container:hover a.hoverColor {
  color: red !important;
}

.system .sectionHeader {
  border-top-color: red !important;
}

.system .alternative a.color {
  color: #fff !important;
}

.system .bg {
  background-color: red !important;
}

.colorHoverMenu .category-title, .colorHoverMenu a.color, .colorHoverMenu .cat-name, .colorHoverMenu a.hoverColor:hover, .colorHoverMenu .article-container:hover, .colorHoverMenu .article-container:hover a.hoverColor {
  color: #540101 !important;
}

.colorHoverMenu .sectionHeader {
  border-top-color: #540101 !important;
}

.colorHoverMenu .alternative a.color {
  color: #fff !important;
}

.colorHoverMenu .bg {
  background-color: #540101 !important;
}

.auto .category-title, .auto a.color, .auto .cat-name, .auto a.hoverColor:hover, .auto .article-container:hover, .auto .article-container:hover a.hoverColor {
  color: red !important;
}

.auto .sectionHeader {
  border-top-color: red !important;
}

.auto .alternative a.color {
  color: #fff !important;
}

.auto .bg {
  background-color: red !important;
}

.pro-zeny .category-title, .pro-zeny a.color, .pro-zeny .cat-name, .pro-zeny a.hoverColor:hover, .pro-zeny .article-container:hover, .pro-zeny .article-container:hover a.hoverColor {
  color: red !important;
}

.pro-zeny .sectionHeader {
  border-top-color: red !important;
}

.pro-zeny .alternative a.color {
  color: #fff !important;
}

.pro-zeny .bg {
  background-color: red !important;
}

.sport .category-title, .sport a.color, .sport .cat-name, .sport a.hoverColor:hover, .sport .article-container:hover, .sport .article-container:hover a.hoverColor {
  color: #91c837 !important;
}

.sport .sectionHeader {
  border-top-color: #91c837 !important;
}

.sport .alternative a.color {
  color: #fff !important;
}

.sport .bg {
  background-color: #91c837 !important;
}

.svet .category-title, .svet a.color, .svet .cat-name, .svet a.hoverColor:hover, .svet .article-container:hover, .svet .article-container:hover a.hoverColor {
  color: #6e78dc !important;
}

.svet .sectionHeader {
  border-top-color: #6e78dc !important;
}

.svet .alternative a.color {
  color: #fff !important;
}

.svet .bg {
  background-color: #6e78dc !important;
}

.tvbox .category-title, .tvbox a.color, .tvbox .cat-name, .tvbox a.hoverColor:hover, .tvbox .article-container:hover, .tvbox .article-container:hover a.hoverColor {
  color: #fff !important;
}

.tvbox .sectionHeader {
  border-top-color: #fff !important;
}

.tvbox .alternative a.color {
  color: #fff !important;
}

.tvbox .bg {
  background-color: #fff !important;
}

.celebrity .category-title, .celebrity a.color, .celebrity .cat-name, .celebrity a.hoverColor:hover, .celebrity .article-container:hover, .celebrity .article-container:hover a.hoverColor {
  color: red !important;
}

.celebrity .sectionHeader {
  border-top-color: red !important;
}

.celebrity .alternative a.color {
  color: #fff !important;
}

.celebrity .bg {
  background-color: red !important;
}

.greyLight .category-title, .greyLight a.color, .greyLight .cat-name, .greyLight a.hoverColor:hover, .greyLight .article-container:hover, .greyLight .article-container:hover a.hoverColor {
  color: #c0ced5 !important;
}

.greyLight .sectionHeader {
  border-top-color: #c0ced5 !important;
}

.greyLight .alternative a.color {
  color: #fff !important;
}

.greyLight .bg {
  background-color: #c0ced5 !important;
}

.grey-comments .category-title, .grey-comments a.color, .grey-comments .cat-name, .grey-comments a.hoverColor:hover, .grey-comments .article-container:hover, .grey-comments .article-container:hover a.hoverColor {
  color: #98a3af !important;
}

.grey-comments .sectionHeader {
  border-top-color: #98a3af !important;
}

.grey-comments .alternative a.color {
  color: #fff !important;
}

.grey-comments .bg {
  background-color: #98a3af !important;
}

.bleskRed .category-title, .bleskRed a.color, .bleskRed .cat-name, .bleskRed a.hoverColor:hover, .bleskRed .article-container:hover, .bleskRed .article-container:hover a.hoverColor {
  color: red !important;
}

.bleskRed .sectionHeader {
  border-top-color: red !important;
}

.bleskRed .alternative a.color {
  color: #fff !important;
}

.bleskRed .bg {
  background-color: red !important;
}

.colorTesty .category-title, .colorTesty a.color, .colorTesty .cat-name, .colorTesty a.hoverColor:hover, .colorTesty .article-container:hover, .colorTesty .article-container:hover a.hoverColor {
  color: red !important;
}

.colorTesty .sectionHeader {
  border-top-color: red !important;
}

.colorTesty .alternative a.color {
  color: #fff !important;
}

.colorTesty .bg {
  background-color: red !important;
}

.heliotrope .category-title, .heliotrope a.color, .heliotrope .cat-name, .heliotrope a.hoverColor:hover, .heliotrope .article-container:hover, .heliotrope .article-container:hover a.hoverColor {
  color: #b469f5 !important;
}

.heliotrope .sectionHeader {
  border-top-color: #b469f5 !important;
}

.heliotrope .alternative a.color {
  color: #fff !important;
}

.heliotrope .bg {
  background-color: #b469f5 !important;
}

.chetwode .category-title, .chetwode a.color, .chetwode .cat-name, .chetwode a.hoverColor:hover, .chetwode .article-container:hover, .chetwode .article-container:hover a.hoverColor {
  color: #083e89 !important;
}

.chetwode .sectionHeader {
  border-top-color: #083e89 !important;
}

.chetwode .alternative a.color {
  color: #fff !important;
}

.chetwode .bg {
  background-color: #083e89 !important;
}

.robinblue .category-title, .robinblue a.color, .robinblue .cat-name, .robinblue a.hoverColor:hover, .robinblue .article-container:hover, .robinblue .article-container:hover a.hoverColor {
  color: #00c8be !important;
}

.robinblue .sectionHeader {
  border-top-color: #00c8be !important;
}

.robinblue .alternative a.color {
  color: #fff !important;
}

.robinblue .bg {
  background-color: #00c8be !important;
}

.hotpink .category-title, .hotpink a.color, .hotpink .cat-name, .hotpink a.hoverColor:hover, .hotpink .article-container:hover, .hotpink .article-container:hover a.hoverColor {
  color: #fe60b2 !important;
}

.hotpink .sectionHeader {
  border-top-color: #fe60b2 !important;
}

.hotpink .alternative a.color {
  color: #fff !important;
}

.hotpink .bg {
  background-color: #fe60b2 !important;
}

.tyrkys .category-title, .tyrkys a.color, .tyrkys .cat-name, .tyrkys a.hoverColor:hover, .tyrkys .article-container:hover, .tyrkys .article-container:hover a.hoverColor {
  color: #2dccd3 !important;
}

.tyrkys .sectionHeader {
  border-top-color: #2dccd3 !important;
}

.tyrkys .alternative a.color {
  color: #fff !important;
}

.tyrkys .bg {
  background-color: #2dccd3 !important;
}

.saffron .category-title, .saffron a.color, .saffron .cat-name, .saffron a.hoverColor:hover, .saffron .article-container:hover, .saffron .article-container:hover a.hoverColor {
  color: #f7b135 !important;
}

.saffron .sectionHeader {
  border-top-color: #f7b135 !important;
}

.saffron .alternative a.color {
  color: #fff !important;
}

.saffron .bg {
  background-color: #f7b135 !important;
}

.jade .category-title, .jade a.color, .jade .cat-name, .jade a.hoverColor:hover, .jade .article-container:hover, .jade .article-container:hover a.hoverColor {
  color: #00b373 !important;
}

.jade .sectionHeader {
  border-top-color: #00b373 !important;
}

.jade .alternative a.color {
  color: #fff !important;
}

.jade .bg {
  background-color: #00b373 !important;
}

.scooter .category-title, .scooter a.color, .scooter .cat-name, .scooter a.hoverColor:hover, .scooter .article-container:hover, .scooter .article-container:hover a.hoverColor {
  color: #3cc3dc !important;
}

.scooter .sectionHeader {
  border-top-color: #3cc3dc !important;
}

.scooter .alternative a.color {
  color: #fff !important;
}

.scooter .bg {
  background-color: #3cc3dc !important;
}

.dodger .category-title, .dodger a.color, .dodger .cat-name, .dodger a.hoverColor:hover, .dodger .article-container:hover, .dodger .article-container:hover a.hoverColor {
  color: #23aaff !important;
}

.dodger .sectionHeader {
  border-top-color: #23aaff !important;
}

.dodger .alternative a.color {
  color: #fff !important;
}

.dodger .bg {
  background-color: #23aaff !important;
}

.domino .category-title, .domino a.color, .domino .cat-name, .domino a.hoverColor:hover, .domino .article-container:hover, .domino .article-container:hover a.hoverColor {
  color: #ff5a00 !important;
}

.domino .sectionHeader {
  border-top-color: #ff5a00 !important;
}

.domino .alternative a.color {
  color: #fff !important;
}

.domino .bg {
  background-color: #ff5a00 !important;
}

.atlantis .category-title, .atlantis a.color, .atlantis .cat-name, .atlantis a.hoverColor:hover, .atlantis .article-container:hover, .atlantis .article-container:hover a.hoverColor {
  color: #91c837 !important;
}

.atlantis .sectionHeader {
  border-top-color: #91c837 !important;
}

.atlantis .alternative a.color {
  color: #fff !important;
}

.atlantis .bg {
  background-color: #91c837 !important;
}

.homeorange .category-title, .homeorange a.color, .homeorange .cat-name, .homeorange a.hoverColor:hover, .homeorange .article-container:hover, .homeorange .article-container:hover a.hoverColor {
  color: #ff7300 !important;
}

.homeorange .sectionHeader {
  border-top-color: #ff7300 !important;
}

.homeorange .alternative a.color {
  color: #fff !important;
}

.homeorange .bg {
  background-color: #ff7300 !important;
}

.dobry-vyrobek {
  width: auto;
  height: 40px;
  position: absolute;
  right: 11px;
}

.gam-wrapper {
  clear: both;
  display: block;
}

.gam-wrapper--padding {
  padding: 20px 0;
}

.gam-wrapper.article-ad-wrapper {
  width: 620px;
  margin-left: -80px;
}

.icon-arrow-left-small {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/arrow-left-small.png") no-repeat;
  width: 8px;
  height: 13px;
}

.icon-arrow-right-big {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/arrow-right-big.png") no-repeat;
  width: 15px;
  height: 25px;
}

.icon-arrow-left-big {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/arrow-left-big.png") no-repeat;
  width: 15px;
  height: 25px;
}

.icon-arrow-right-small {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/arrow-right-small.png") no-repeat;
  width: 8px;
  height: 13px;
}

.icon-blesk_logo_medium {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/blesk_logo_medium.png") no-repeat;
  width: 154px;
  height: 70px;
}

.icon-blesk_mobil {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/blesk_mobil.png") no-repeat;
  width: 52px;
  height: 34px;
}

.icon-blesk_logo_big {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/blesk_logo_big.png") no-repeat;
  width: 232px;
  height: 113px;
}

.icon-blesk_srdce {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/blesk_srdce.png") no-repeat;
  width: 44px;
  height: 35px;
}

.icon-blesk_vyhledavac {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/blesk_vyhledavac.png") no-repeat;
  width: 21px;
  height: 21px;
}

.icon-bleskove_zpravy_logo {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/bleskove_zpravy_logo.png") no-repeat;
  width: 83px;
  height: 27px;
}

.icon-dnes_na_autocz {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/dnes_na_autocz.png") no-repeat;
  width: 306px;
  height: 46px;
}

.icon-dnes_na_isportcz {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/dnes_na_isportcz.png") no-repeat;
  width: 138px;
  height: 57px;
}

.icon-dobry_telefon {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/dobry_telefon.png") no-repeat;
  width: 66px;
  height: 46px;
}

.icon-dobry_vyrobek {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/dobry_vyrobek.png") no-repeat;
  width: 66px;
  height: 46px;
}

.icon-hobby-log-106x46 {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/hobby-log-106x46.png") no-repeat;
  width: 106px;
  height: 46px;
}

.icon-icon-notification {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/icon-notification.png") no-repeat;
  width: 50px;
  height: 63px;
}

.icon-icon_camera_big {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/icon_camera_big.png") no-repeat;
  width: 42px;
  height: 41px;
}

.icon-icon_grey_camera {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/icon_grey_camera.png") no-repeat;
  width: 14px;
  height: 11px;
}

.icon-icon_grey_comments {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/icon_grey_comments.png") no-repeat;
  width: 15px;
  height: 13px;
}

.icon-frantiska-icon-s-podpisem-big {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/frantiska-icon-s-podpisem-big.png") no-repeat;
  width: 312px;
  height: 298px;
}

.icon-icon_grey_share {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/icon_grey_share.png") no-repeat;
  width: 14px;
  height: 12px;
}

.icon-icon_horoskopy {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/icon_horoskopy.png") no-repeat;
  width: 24px;
  height: 19px;
}

.icon-icon_tv_program {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/icon_tv_program.png") no-repeat;
  width: 29px;
  height: 21px;
}

.icon-icon_white_eye {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/icon_white_eye.png") no-repeat;
  width: 15px;
  height: 9px;
}

.icon-isport-gray {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/isport-gray.png") no-repeat;
  width: 125px;
  height: 42px;
}

.icon-jakzhubnout-logo-106x46 {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/jakzhubnout-logo-106x46.png") no-repeat;
  width: 106px;
  height: 46px;
}

.icon-jasno {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/jasno.png") no-repeat;
  width: 40px;
  height: 39px;
}

.icon-lesk_logo {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/lesk_logo.png") no-repeat;
  width: 61px;
  height: 24px;
}

.icon-logo24_40x40 {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/logo24_40x40.png") no-repeat;
  width: 40px;
  height: 40px;
}

.icon-logo24_inverze {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/logo24_inverze.png") no-repeat;
  width: 40px;
  height: 40px;
}

.icon-logo24_sikme_behavioral {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/logo24_sikme_behavioral.png") no-repeat;
  width: 21px;
  height: 21px;
}

.icon-logo24_sikme_header {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/logo24_sikme_header.png") no-repeat;
  width: 33px;
  height: 33px;
}

.icon-logo24_sikme_rubrika {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/logo24_sikme_rubrika.png") no-repeat;
  width: 21px;
  height: 20px;
}

.icon-logo24_sikme_rubrikaHP {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/logo24_sikme_rubrikaHP.png") no-repeat;
  width: 38px;
  height: 37px;
}

.icon-mainlogo24hodin_HPrubric {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/mainlogo24hodin_HPrubric.png") no-repeat;
  width: 223px;
  height: 69px;
}

.icon-mainlogo_24hodin {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/mainlogo_24hodin.png") no-repeat;
  width: 223px;
  height: 69px;
}

.icon-mlha-dest {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/mlha-dest.png") no-repeat;
  width: 36px;
  height: 33px;
}

.icon-mlha-snih {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/mlha-snih.png") no-repeat;
  width: 37px;
  height: 33px;
}

.icon-mlha {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/mlha.png") no-repeat;
  width: 36px;
  height: 32px;
}

.icon-oblacno-bourka {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/oblacno-bourka.png") no-repeat;
  width: 36px;
  height: 42px;
}

.icon-oblacno-dest-snih {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/oblacno-dest-snih.png") no-repeat;
  width: 37px;
  height: 41px;
}

.icon-oblacno-dest {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/oblacno-dest.png") no-repeat;
  width: 39px;
  height: 40px;
}

.icon-oblacno-snih {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/oblacno-snih.png") no-repeat;
  width: 36px;
  height: 42px;
}

.icon-oblacno {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/oblacno.png") no-repeat;
  width: 41px;
  height: 32px;
}

.icon-play-small {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/play-small.png") no-repeat;
  width: 18px;
  height: 19px;
}

.icon-play_icon_big {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/play_icon_big.png") no-repeat;
  width: 42px;
  height: 41px;
}

.icon-play_icon_small {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/play_icon_small.png") no-repeat;
  width: 29px;
  height: 28px;
}

.icon-polojasno-bourka {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/polojasno-bourka.png") no-repeat;
  width: 36px;
  height: 42px;
}

.icon-polojasno-dest {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/polojasno-dest.png") no-repeat;
  width: 39px;
  height: 40px;
}

.icon-polojasno-snih {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/polojasno-snih.png") no-repeat;
  width: 36px;
  height: 42px;
}

.icon-polojasno {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/polojasno.png") no-repeat;
  width: 37px;
  height: 35px;
}

.icon-search-cross {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/search-cross.png") no-repeat;
  width: 21px;
  height: 21px;
}

.icon-supermamy-logo-106x46 {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/supermamy-logo-106x46.png") no-repeat;
  width: 106px;
  height: 46px;
}

.icon-test_big {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/test_big.png") no-repeat;
  width: 100px;
  height: 93px;
}

.icon-test_biggest {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/test_biggest.png") no-repeat;
  width: 130px;
  height: 121px;
}

.icon-test_small {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/test_small.png") no-repeat;
  width: 69px;
  height: 64px;
}

.icon-tv_blesk_logo_middle {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/tv_blesk_logo_middle.png") no-repeat;
  width: 141px;
  height: 40px;
}

.icon-tv_blesk_logo_big {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/tv_blesk_logo_big.png") no-repeat;
  width: 190px;
  height: 54px;
}

.icon-tv_blesk_logo {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/tv_blesk_logo.png") no-repeat;
  width: 96px;
  height: 28px;
}

.icon-tv_isport_logo {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/tv_isport_logo.png") no-repeat;
  width: 80px;
  height: 30px;
}

.icon-zatazeno-bourka {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/zatazeno-bourka.png") no-repeat;
  width: 34px;
  height: 37px;
}

.icon-zatazeno-dest-snih {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/zatazeno-dest-snih.png") no-repeat;
  width: 34px;
  height: 37px;
}

.icon-zatazeno-dest {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/zatazeno-dest.png") no-repeat;
  width: 34px;
  height: 36px;
}

.icon-zatazeno-snih {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/zatazeno-snih.png") no-repeat;
  width: 34px;
  height: 37px;
}

.icon-zatazeno {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/zatazeno.png") no-repeat;
  width: 36px;
  height: 28px;
}

.grid_1, .grid_2, .grid_3, .grid_4, .grid_5, .grid_6, .grid_7, .grid_8, .grid_9, .grid_10, .grid_11, .grid_12 {
  float: left;
  margin: 0;
  display: block;
}

.container .grid_1 {
  width: 80px;
}

.container .grid_2 {
  width: 160px;
}

.container .grid_3 {
  width: 240px;
}

.container .grid_4 {
  width: 320px;
}

.container .grid_5 {
  width: 400px;
}

.container .grid_6 {
  width: 480px;
}

.container .grid_7 {
  width: 560px;
}

.container .grid_8 {
  width: 640px;
}

.container .grid_9 {
  width: 720px;
}

.container .grid_10 {
  width: 800px;
}

.container .grid_11 {
  width: 880px;
}

.container .grid_12 {
  width: 960px;
}

.inner_1, .inner_2, .inner_3, .inner_4, .inner_5, .inner_6, .inner_7, .inner_8, .inner_9, .inner_10, .inner_11, .inner_12 {
  float: left;
  margin: 0 0 10px;
  padding: 10px 10px 0;
  display: block;
}

.outer_1, .outer_2, .outer_3, .outer_4, .outer_5, .outer_6, .outer_7, .outer_8, .outer_9, .outer_10, .outer_11, .outer_12 {
  float: left;
  margin: 0 10px;
  display: block;
}

.container .outer_1, .container .inner_1 {
  width: 60px;
}

.container .outer_2, .container .inner_2 {
  width: 140px;
}

.container .outer_3, .container .inner_3 {
  width: 220px;
}

.container .outer_4, .container .inner_4 {
  width: 300px;
}

.container .outer_5, .container .inner_5 {
  width: 380px;
}

.container .outer_6, .container .inner_6 {
  width: 460px;
}

.container .outer_7, .container .inner_7 {
  width: 540px;
}

.container .outer_8, .container .inner_8 {
  width: 620px;
}

.container .outer_9, .container .inner_9 {
  width: 700px;
}

.container .outer_10, .container .inner_10 {
  width: 780px;
}

.container .outer_11, .container .inner_11 {
  width: 860px;
}

.container .outer_12, .container .inner_12 {
  width: 940px;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mr-0 {
  margin-right: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.m-5 {
  margin: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.m-10 {
  margin: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.m-15 {
  margin: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.m-20 {
  margin: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.m-25 {
  margin: 25px;
}

.mt-25 {
  margin-top: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.m-30 {
  margin: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.m-35 {
  margin: 35px;
}

.mt-35 {
  margin-top: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.m-40 {
  margin: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.m-45 {
  margin: 45px;
}

.mt-45 {
  margin-top: 45px;
}

.mr-45 {
  margin-right: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.ml-45 {
  margin-left: 45px;
}

.m-50 {
  margin: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.m-55 {
  margin: 55px;
}

.mt-55 {
  margin-top: 55px;
}

.mr-55 {
  margin-right: 55px;
}

.mb-55 {
  margin-bottom: 55px;
}

.ml-55 {
  margin-left: 55px;
}

.m-60 {
  margin: 60px;
}

.mt-60 {
  margin-top: 60px;
}

.mr-60 {
  margin-right: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.m-65 {
  margin: 65px;
}

.mt-65 {
  margin-top: 65px;
}

.mr-65 {
  margin-right: 65px;
}

.mb-65 {
  margin-bottom: 65px;
}

.ml-65 {
  margin-left: 65px;
}

.m-70 {
  margin: 70px;
}

.mt-70 {
  margin-top: 70px;
}

.mr-70 {
  margin-right: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.ml-70 {
  margin-left: 70px;
}

.m-75 {
  margin: 75px;
}

.mt-75 {
  margin-top: 75px;
}

.mr-75 {
  margin-right: 75px;
}

.mb-75 {
  margin-bottom: 75px;
}

.ml-75 {
  margin-left: 75px;
}

.m-80 {
  margin: 80px;
}

.mt-80 {
  margin-top: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.m-85 {
  margin: 85px;
}

.mt-85 {
  margin-top: 85px;
}

.mr-85 {
  margin-right: 85px;
}

.mb-85 {
  margin-bottom: 85px;
}

.ml-85 {
  margin-left: 85px;
}

.m-90 {
  margin: 90px;
}

.mt-90 {
  margin-top: 90px;
}

.mr-90 {
  margin-right: 90px;
}

.mb-90 {
  margin-bottom: 90px;
}

.ml-90 {
  margin-left: 90px;
}

.m-95 {
  margin: 95px;
}

.mt-95 {
  margin-top: 95px;
}

.mr-95 {
  margin-right: 95px;
}

.mb-95 {
  margin-bottom: 95px;
}

.ml-95 {
  margin-left: 95px;
}

.m-100 {
  margin: 100px;
}

.mt-100 {
  margin-top: 100px;
}

.mr-100 {
  margin-right: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-100 {
  margin-left: 100px;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.p-5 {
  padding: 5px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.p-35 {
  padding: 35px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.p-40 {
  padding: 40px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.p-45 {
  padding: 45px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.p-50 {
  padding: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.p-55 {
  padding: 55px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.pr-55 {
  padding-right: 55px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.pl-55 {
  padding-left: 55px !important;
}

.p-60 {
  padding: 60px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.p-65 {
  padding: 65px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.pr-65 {
  padding-right: 65px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.pl-65 {
  padding-left: 65px !important;
}

.p-70 {
  padding: 70px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.p-75 {
  padding: 75px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.pr-75 {
  padding-right: 75px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.pl-75 {
  padding-left: 75px !important;
}

.p-80 {
  padding: 80px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.p-85 {
  padding: 85px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.pr-85 {
  padding-right: 85px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.pl-85 {
  padding-left: 85px !important;
}

.p-90 {
  padding: 90px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.p-95 {
  padding: 95px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.pr-95 {
  padding-right: 95px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.pl-95 {
  padding-left: 95px !important;
}

.p-100 {
  padding: 100px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.p-105 {
  padding: 105px !important;
}

.pt-105 {
  padding-top: 105px !important;
}

.pr-105 {
  padding-right: 105px !important;
}

.pb-105 {
  padding-bottom: 105px !important;
}

.pl-105 {
  padding-left: 105px !important;
}

.p-110 {
  padding: 110px !important;
}

.pt-110 {
  padding-top: 110px !important;
}

.pr-110 {
  padding-right: 110px !important;
}

.pb-110 {
  padding-bottom: 110px !important;
}

.pl-110 {
  padding-left: 110px !important;
}

.p-115 {
  padding: 115px !important;
}

.pt-115 {
  padding-top: 115px !important;
}

.pr-115 {
  padding-right: 115px !important;
}

.pb-115 {
  padding-bottom: 115px !important;
}

.pl-115 {
  padding-left: 115px !important;
}

.p-120 {
  padding: 120px !important;
}

.pt-120 {
  padding-top: 120px !important;
}

.pr-120 {
  padding-right: 120px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

.pl-120 {
  padding-left: 120px !important;
}

.p-125 {
  padding: 125px !important;
}

.pt-125 {
  padding-top: 125px !important;
}

.pr-125 {
  padding-right: 125px !important;
}

.pb-125 {
  padding-bottom: 125px !important;
}

.pl-125 {
  padding-left: 125px !important;
}

.p-130 {
  padding: 130px !important;
}

.pt-130 {
  padding-top: 130px !important;
}

.pr-130 {
  padding-right: 130px !important;
}

.pb-130 {
  padding-bottom: 130px !important;
}

.pl-130 {
  padding-left: 130px !important;
}

.p-135 {
  padding: 135px !important;
}

.pt-135 {
  padding-top: 135px !important;
}

.pr-135 {
  padding-right: 135px !important;
}

.pb-135 {
  padding-bottom: 135px !important;
}

.pl-135 {
  padding-left: 135px !important;
}

.p-140 {
  padding: 140px !important;
}

.pt-140 {
  padding-top: 140px !important;
}

.pr-140 {
  padding-right: 140px !important;
}

.pb-140 {
  padding-bottom: 140px !important;
}

.pl-140 {
  padding-left: 140px !important;
}

.p-145 {
  padding: 145px !important;
}

.pt-145 {
  padding-top: 145px !important;
}

.pr-145 {
  padding-right: 145px !important;
}

.pb-145 {
  padding-bottom: 145px !important;
}

.pl-145 {
  padding-left: 145px !important;
}

.p-150 {
  padding: 150px !important;
}

.pt-150 {
  padding-top: 150px !important;
}

.pr-150 {
  padding-right: 150px !important;
}

.pb-150 {
  padding-bottom: 150px !important;
}

.pl-150 {
  padding-left: 150px !important;
}

.p-155 {
  padding: 155px !important;
}

.pt-155 {
  padding-top: 155px !important;
}

.pr-155 {
  padding-right: 155px !important;
}

.pb-155 {
  padding-bottom: 155px !important;
}

.pl-155 {
  padding-left: 155px !important;
}

.p-160 {
  padding: 160px !important;
}

.pt-160 {
  padding-top: 160px !important;
}

.pr-160 {
  padding-right: 160px !important;
}

.pb-160 {
  padding-bottom: 160px !important;
}

.pl-160 {
  padding-left: 160px !important;
}

.p-165 {
  padding: 165px !important;
}

.pt-165 {
  padding-top: 165px !important;
}

.pr-165 {
  padding-right: 165px !important;
}

.pb-165 {
  padding-bottom: 165px !important;
}

.pl-165 {
  padding-left: 165px !important;
}

.p-170 {
  padding: 170px !important;
}

.pt-170 {
  padding-top: 170px !important;
}

.pr-170 {
  padding-right: 170px !important;
}

.pb-170 {
  padding-bottom: 170px !important;
}

.pl-170 {
  padding-left: 170px !important;
}

.p-175 {
  padding: 175px !important;
}

.pt-175 {
  padding-top: 175px !important;
}

.pr-175 {
  padding-right: 175px !important;
}

.pb-175 {
  padding-bottom: 175px !important;
}

.pl-175 {
  padding-left: 175px !important;
}

.p-180 {
  padding: 180px !important;
}

.pt-180 {
  padding-top: 180px !important;
}

.pr-180 {
  padding-right: 180px !important;
}

.pb-180 {
  padding-bottom: 180px !important;
}

.pl-180 {
  padding-left: 180px !important;
}

.p-185 {
  padding: 185px !important;
}

.pt-185 {
  padding-top: 185px !important;
}

.pr-185 {
  padding-right: 185px !important;
}

.pb-185 {
  padding-bottom: 185px !important;
}

.pl-185 {
  padding-left: 185px !important;
}

.p-190 {
  padding: 190px !important;
}

.pt-190 {
  padding-top: 190px !important;
}

.pr-190 {
  padding-right: 190px !important;
}

.pb-190 {
  padding-bottom: 190px !important;
}

.pl-190 {
  padding-left: 190px !important;
}

.p-195 {
  padding: 195px !important;
}

.pt-195 {
  padding-top: 195px !important;
}

.pr-195 {
  padding-right: 195px !important;
}

.pb-195 {
  padding-bottom: 195px !important;
}

.pl-195 {
  padding-left: 195px !important;
}

.p-200 {
  padding: 200px !important;
}

.pt-200 {
  padding-top: 200px !important;
}

.pr-200 {
  padding-right: 200px !important;
}

.pb-200 {
  padding-bottom: 200px !important;
}

.pl-200 {
  padding-left: 200px !important;
}

.top-article-wrap-move {
  margin-top: 420px;
}

.top-article-wrap {
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
}

.top-article-wrap h2.title, .top-article-wrap h3.title {
  float: left;
  letter-spacing: 0;
  padding: 6px 20px 4px;
  font-size: 30px;
  line-height: 32px;
  position: relative;
  overflow: visible;
}

.top-article-wrap h2.title a, .top-article-wrap h3.title a {
  color: #000;
  transition: all .2s ease-out;
}

.top-article-wrap .whitecolor:hover h2.title a, .top-article-wrap .whitecolor:hover h3.title a, .top-article-wrap .whitecolor:hover .cat-name {
  color: #fff !important;
}

.top-article-wrap .tvblack:hover h2.title a, .top-article-wrap .tvblack:hover h3.title a, .top-article-wrap .tvblack:hover .cat-name {
  color: #16212d !important;
}

.top-article-wrap .pureBlack:hover h2.title a, .top-article-wrap .pureBlack:hover h3.title a, .top-article-wrap .pureBlack:hover .cat-name {
  color: #000 !important;
}

.top-article-wrap .tvblacklighter:hover h2.title a, .top-article-wrap .tvblacklighter:hover h3.title a, .top-article-wrap .tvblacklighter:hover .cat-name {
  color: #232b3a !important;
}

.top-article-wrap .redChristmas:hover h2.title a, .top-article-wrap .redChristmas:hover h3.title a, .top-article-wrap .redChristmas:hover .cat-name {
  color: #b00 !important;
}

.top-article-wrap .pinkChristmas:hover h2.title a, .top-article-wrap .pinkChristmas:hover h3.title a, .top-article-wrap .pinkChristmas:hover .cat-name {
  color: #ff8a8a !important;
}

.top-article-wrap .default:hover h2.title a, .top-article-wrap .default:hover h3.title a, .top-article-wrap .default:hover .cat-name {
  color: #000 !important;
}

.top-article-wrap .grey:hover h2.title a, .top-article-wrap .grey:hover h3.title a, .top-article-wrap .grey:hover .cat-name {
  color: #c9d3df !important;
}

.top-article-wrap .system:hover h2.title a, .top-article-wrap .system:hover h3.title a, .top-article-wrap .system:hover .cat-name {
  color: red !important;
}

.top-article-wrap .colorHoverMenu:hover h2.title a, .top-article-wrap .colorHoverMenu:hover h3.title a, .top-article-wrap .colorHoverMenu:hover .cat-name {
  color: #540101 !important;
}

.top-article-wrap .auto:hover h2.title a, .top-article-wrap .auto:hover h3.title a, .top-article-wrap .auto:hover .cat-name, .top-article-wrap .pro-zeny:hover h2.title a, .top-article-wrap .pro-zeny:hover h3.title a, .top-article-wrap .pro-zeny:hover .cat-name {
  color: red !important;
}

.top-article-wrap .sport:hover h2.title a, .top-article-wrap .sport:hover h3.title a, .top-article-wrap .sport:hover .cat-name {
  color: #91c837 !important;
}

.top-article-wrap .svet:hover h2.title a, .top-article-wrap .svet:hover h3.title a, .top-article-wrap .svet:hover .cat-name {
  color: #6e78dc !important;
}

.top-article-wrap .tvbox:hover h2.title a, .top-article-wrap .tvbox:hover h3.title a, .top-article-wrap .tvbox:hover .cat-name {
  color: #fff !important;
}

.top-article-wrap .celebrity:hover h2.title a, .top-article-wrap .celebrity:hover h3.title a, .top-article-wrap .celebrity:hover .cat-name {
  color: red !important;
}

.top-article-wrap .greyLight:hover h2.title a, .top-article-wrap .greyLight:hover h3.title a, .top-article-wrap .greyLight:hover .cat-name {
  color: #c0ced5 !important;
}

.top-article-wrap .grey-comments:hover h2.title a, .top-article-wrap .grey-comments:hover h3.title a, .top-article-wrap .grey-comments:hover .cat-name {
  color: #98a3af !important;
}

.top-article-wrap .bleskRed:hover h2.title a, .top-article-wrap .bleskRed:hover h3.title a, .top-article-wrap .bleskRed:hover .cat-name, .top-article-wrap .colorTesty:hover h2.title a, .top-article-wrap .colorTesty:hover h3.title a, .top-article-wrap .colorTesty:hover .cat-name {
  color: red !important;
}

.top-article-wrap .heliotrope:hover h2.title a, .top-article-wrap .heliotrope:hover h3.title a, .top-article-wrap .heliotrope:hover .cat-name {
  color: #b469f5 !important;
}

.top-article-wrap .chetwode:hover h2.title a, .top-article-wrap .chetwode:hover h3.title a, .top-article-wrap .chetwode:hover .cat-name {
  color: #083e89 !important;
}

.top-article-wrap .robinblue:hover h2.title a, .top-article-wrap .robinblue:hover h3.title a, .top-article-wrap .robinblue:hover .cat-name {
  color: #00c8be !important;
}

.top-article-wrap .hotpink:hover h2.title a, .top-article-wrap .hotpink:hover h3.title a, .top-article-wrap .hotpink:hover .cat-name {
  color: #fe60b2 !important;
}

.top-article-wrap .tyrkys:hover h2.title a, .top-article-wrap .tyrkys:hover h3.title a, .top-article-wrap .tyrkys:hover .cat-name {
  color: #2dccd3 !important;
}

.top-article-wrap .saffron:hover h2.title a, .top-article-wrap .saffron:hover h3.title a, .top-article-wrap .saffron:hover .cat-name {
  color: #f7b135 !important;
}

.top-article-wrap .jade:hover h2.title a, .top-article-wrap .jade:hover h3.title a, .top-article-wrap .jade:hover .cat-name {
  color: #00b373 !important;
}

.top-article-wrap .scooter:hover h2.title a, .top-article-wrap .scooter:hover h3.title a, .top-article-wrap .scooter:hover .cat-name {
  color: #3cc3dc !important;
}

.top-article-wrap .dodger:hover h2.title a, .top-article-wrap .dodger:hover h3.title a, .top-article-wrap .dodger:hover .cat-name {
  color: #23aaff !important;
}

.top-article-wrap .domino:hover h2.title a, .top-article-wrap .domino:hover h3.title a, .top-article-wrap .domino:hover .cat-name {
  color: #ff5a00 !important;
}

.top-article-wrap .atlantis:hover h2.title a, .top-article-wrap .atlantis:hover h3.title a, .top-article-wrap .atlantis:hover .cat-name {
  color: #91c837 !important;
}

.top-article-wrap .homeorange:hover h2.title a, .top-article-wrap .homeorange:hover h3.title a, .top-article-wrap .homeorange:hover .cat-name {
  color: #ff7300 !important;
}

.top-article-wrap .articleContext {
  margin-top: 15px;
  font-family: arial;
  font-size: 12px;
  line-height: 15px;
  position: relative;
  bottom: 8px;
  left: 20px;
}

.top-article-wrap .articleContext a, .top-article-wrap .articleContext .videoTitle {
  color: #98a3af;
}

.top-article-wrap .articleContext .forumTitle, .top-article-wrap .articleContext .galleryTitle, .top-article-wrap .articleContext .videoTitle {
  padding: 0 3px;
}

.top-article-wrap .articleContext .forumCount, .top-article-wrap .articleContext .galleryCount, .top-article-wrap .articleContext .videoCount {
  color: #98a3af;
  margin-right: 10px;
}

.top-article-wrap .articleContext .forumIcon {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -800px no-repeat;
  width: 13px;
  height: 11px;
  display: inline-block;
  position: relative;
  top: 1px;
}

.top-article-wrap .articleContext .galleryIcon {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0 -800px no-repeat;
  width: 12px;
  height: 9px;
  display: inline-block;
}

.top-article-wrap .cat-name {
  text-transform: uppercase;
  letter-spacing: .5px;
  font-size: 14px;
}

.top-article-wrap .item-middle-top {
  background: #fff;
  width: 300px;
  height: 330px;
  margin: 10px 10px 20px;
  position: relative;
  overflow: hidden;
}

.top-article-wrap .item-middle-top .preview {
  width: 300px;
  height: 180px;
  position: relative;
  overflow: hidden;
}

.top-article-wrap .item-middle-top .preview img {
  width: 300px;
  min-height: 190px;
  position: relative;
  top: -10px;
}

.top-article-wrap .item-middle-top .content {
  width: 300px;
  margin-top: 10px;
  position: relative;
}

.top-article-wrap .item-middle-top .content .cat-name {
  background-color: #fff;
  padding: 8px 10px 5px 20px;
  font-size: 14px;
  display: block;
  position: absolute;
  top: -30px;
}

.top-article-wrap .item-middle-top .content .cat-name.two-lines {
  max-width: 105px;
  top: -50px;
}

.top-article-wrap .item-middle-top .content .title {
  max-width: 300px;
  min-height: 175px;
  max-height: 195px;
  padding: 3px 20px 0;
  line-height: 35px;
  overflow: hidden;
}

.top-article-wrap .item-middle-top .media-icon {
  position: absolute;
  top: 150px;
  left: 135px;
}

.top-article-wrap .item-middle {
  clear: both;
  background: #fff;
  width: 620px;
  height: 180px;
  margin: 0 auto 20px;
  position: relative;
  overflow: hidden;
}

.top-article-wrap .item-middle h2.title a, .top-article-wrap .item-middle h3.title a {
  line-height: 29px;
}

.top-article-wrap .item-middle .preview {
  float: left;
  width: 300px;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.top-article-wrap .item-middle .content {
  float: left;
  width: 310px;
  margin-left: 10px;
}

.top-article-wrap .item-middle .content .cat-name {
  padding: 20px 0 0 20px;
  font-size: 14px;
  display: block;
  position: relative;
}

.top-article-wrap .item-middle .content .cat-name.insideImage {
  text-align: right;
  width: 300px;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.top-article-wrap .item-middle .content .cat-name.insideImage span {
  white-space: nowrap;
  background-color: #fff;
  padding: 9px 10px 5px;
  display: inline-block;
}

.top-article-wrap .item-middle .content .cat-name:hover, .top-article-wrap .item-middle .content .cat-name:hover span {
  text-decoration: underline;
}

.top-article-wrap .item-middle .content .title {
  max-width: 320px;
  min-height: 110px;
  max-height: 145px;
  padding-top: 16px;
  line-height: 29px;
  display: inline-block;
  overflow: hidden;
}

.top-article-wrap .item-middle .media-icon {
  position: absolute;
  top: 70px;
  left: 280px;
}

.top-article-wrap .item-big {
  background: #fff;
  width: 620px;
  height: 390px;
  margin: 0 auto 20px;
  position: relative;
  overflow: hidden;
}

.top-article-wrap .item-big .preview {
  width: 100%;
  height: 240px;
  position: relative;
  overflow: hidden;
}

.top-article-wrap .item-big .preview img {
  width: 620px;
  position: relative;
  top: -4px;
}

.top-article-wrap .item-big .content {
  width: 600px;
  position: relative;
}

.top-article-wrap .item-big .content .cat-name {
  letter-spacing: .5px;
  text-transform: uppercase;
  background-color: #fff;
  padding: 8px 10px 5px 20px;
  font-size: 14px;
  display: block;
  position: absolute;
  top: -18px;
}

.top-article-wrap .item-big .content .title {
  width: 100%;
  max-width: 600px;
  min-height: 98px;
  padding-top: 19px;
  font-size: 40px;
  line-height: 106%;
}

.top-article-wrap .item-big .media-icon {
  position: absolute;
  top: 216px;
  left: 290px;
}

.tabsArea-move {
  position: absolute;
  top: 0;
}

.tabsArea#behavioral-tab {
  color: #fff;
  width: 620px;
  margin: 20px 10px;
  position: relative;
}

.tabsArea#behavioral-tab li, .tabsArea#behavioral-tab ul {
  list-style: none;
}

.tabsArea#behavioral-tab .tabsNavigation {
  height: 40px;
  overflow: hidden;
}

.tabsArea#behavioral-tab .tabsNavigation li {
  float: left;
  box-sizing: border-box;
  margin: 0;
  display: block;
}

.tabsArea#behavioral-tab .tabsNavigation li.celebrity a.active {
  color: #fff;
  background-color: red;
}

.tabsArea#behavioral-tab .tabsNavigation li.pro-zeny a.active {
  color: #fff;
  background-color: #fe60b2;
}

.tabsArea#behavioral-tab .tabsNavigation li.sport a.active {
  color: #fff;
  background-color: #91c837;
}

.tabsArea#behavioral-tab .tabsNavigation li.zpravy a.active {
  color: #fff;
  background-color: #083e89;
}

.tabsArea#behavioral-tab .tabsNavigation li:nth-child(3) a {
  z-index: 2 !important;
}

.tabsArea#behavioral-tab .tabsNavigation li:nth-child(4) a {
  z-index: 3 !important;
}

.tabsArea#behavioral-tab .tabsNavigation li a {
  text-align: center;
  color: #000;
  text-transform: uppercase;
  box-sizing: border-box;
  z-index: 0;
  background-color: #fff;
  width: 155px;
  height: 40px;
  padding: 5px;
  font-family: tablet_gothic_condensed;
  font-size: 28px;
  line-height: 1.4;
  text-decoration: none;
  transition: all .2s ease-out;
  display: block;
  position: relative;
}

.tabsArea#behavioral-tab .tabsNavigation li a.active {
  z-index: 1;
}

.tabsArea#behavioral-tab .tabsNavigation li a.active:after {
  content: "";
  z-index: -1;
  background: none;
  width: 155px;
  max-width: 260px;
  height: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 0 9px #000;
}

.tabsArea#behavioral-tab .tabsNavigation li.zpravy a .blesk24logoBehav {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0);
  width: 22px;
  height: 22px;
  position: absolute;
  top: 10px;
  left: 120px;
}

.tabsArea .behavioral-tabcontent-wrap {
  box-sizing: border-box;
  width: 620px;
  height: 340px;
  padding: 20px 0 20px 15px;
  font-family: tablet_gothic_condensed;
  position: relative;
  overflow: hidden;
}

.tabsArea .behavioral-tabcontent-wrap .item-middle {
  float: left;
  width: 280px;
  height: 73px;
  max-height: 73px;
  margin: 0 0 18px 20px;
  padding: 0 0 15px;
  position: relative;
  overflow: hidden;
}

.tabsArea .behavioral-tabcontent-wrap .item-middle h3.title {
  float: left;
  letter-spacing: 0;
  width: 140px;
  height: 72px;
  font-family: arial, sans-serif;
  font-size: 15px;
  line-height: 18px;
  position: relative;
  overflow: hidden;
}

.tabsArea .behavioral-tabcontent-wrap .item-middle h3.title a {
  color: #fff;
}

.tabsArea .behavioral-tabcontent-wrap .item-middle .preview {
  float: left;
  width: 120px;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.tabsArea .behavioral-tabcontent-wrap .item-middle .content {
  float: left;
  width: 160px;
  position: relative;
}

.tabsArea .behavioral-tabcontent-wrap .item-big {
  float: left;
  width: 295px;
  height: 316px;
  margin: 0 auto 20px;
  position: relative;
  overflow: hidden;
}

.tabsArea .behavioral-tabcontent-wrap .item-big h3.title {
  float: left;
  letter-spacing: 0;
  width: 280px;
  margin: 5px 0 20px;
  padding-top: 6px;
  font-size: 30px;
  line-height: 32px;
  position: relative;
  overflow: hidden;
}

.tabsArea .behavioral-tabcontent-wrap .item-big h3.title a {
  color: #fff;
}

.tabsArea .behavioral-tabcontent-wrap .item-big .preview {
  width: 280px;
  height: 180px;
  position: relative;
  overflow: hidden;
}

.tabsArea .behavioral-tabcontent-wrap .item-big .preview img {
  width: 100%;
}

.tabsArea .behavioral-tabcontent-wrap .item-big .content {
  width: 305px;
  position: relative;
}

.tabsArea .behavioral-tabcontent-wrap .item-big .content .cat-name {
  letter-spacing: .5px;
  text-transform: uppercase;
  max-width: 110px;
  padding: 8px 10px 5px 0;
  font-size: 14px;
  position: absolute;
  bottom: -5px;
}

.tabsArea .behavioral-tabcontent-wrap .item-big .content .icon.behabox {
  position: absolute;
  top: -27px;
  left: 119px;
}

.tabsArea .behavioral-tabcontent-wrap .item-big.item-big-last {
  border: 0 solid rgba(0, 0, 0, 0);
  width: 274px;
  margin-left: 15px;
}

.celebrity .behavioral-tabcontent-wrap {
  background-color: red;
}

.pro-zeny .behavioral-tabcontent-wrap {
  background-color: #fe60b2;
}

.sport .behavioral-tabcontent-wrap {
  background-color: #91c837;
}

.zpravy .behavioral-tabcontent-wrap {
  background-color: #083e89;
}

.celebrity .behavioral-tabcontent-wrap .item-big {
  border-right: 1px solid rgba(255, 255, 255, .2);
}

.celebrity .behavioral-tabcontent-wrap .item-middle {
  border-bottom: 1px solid rgba(255, 255, 255, .2);
}

.celebrity .behavioral-tabcontent-wrap .cat-name {
  background-color: red;
}

.celebrity .behavioral-tabcontent-wrap .cat-name a {
  color: #f1adad;
}

.pro-zeny .behavioral-tabcontent-wrap .item-big {
  border-right: 1px solid rgba(255, 255, 255, .2);
}

.pro-zeny .behavioral-tabcontent-wrap .item-middle {
  border-bottom: 1px solid rgba(255, 255, 255, .2);
}

.pro-zeny .behavioral-tabcontent-wrap .cat-name {
  background-color: #fe60b2;
}

.pro-zeny .behavioral-tabcontent-wrap .cat-name a {
  color: #efcbdc;
}

.sport .behavioral-tabcontent-wrap .item-big {
  border-right: 1px solid rgba(255, 255, 255, .2);
}

.sport .behavioral-tabcontent-wrap .item-middle {
  border-bottom: 1px solid rgba(255, 255, 255, .2);
}

.sport .behavioral-tabcontent-wrap .cat-name {
  background-color: #91c837;
}

.sport .behavioral-tabcontent-wrap .cat-name a {
  color: #e1ffb0;
}

.zpravy .behavioral-tabcontent-wrap .item-big {
  border-right: 1px solid rgba(255, 255, 255, .2);
}

.zpravy .behavioral-tabcontent-wrap .item-middle {
  border-bottom: 1px solid rgba(255, 255, 255, .2);
}

.zpravy .behavioral-tabcontent-wrap .cat-name {
  background-color: #083e89;
}

.zpravy .behavioral-tabcontent-wrap .cat-name a {
  color: #b7b7f9;
}

.behavioral-tabcontent-wrap .item-middle.position-4 {
  border-bottom: 0 solid #000;
}

#muzi .sectionHeader {
  border-top-color: #91c837 !important;
}

#muzi a.color {
  color: #91c837 !important;
}

#muzi .article-container {
  border-top-color: #91c837 !important;
}

#muzi .article-container a.hoverColor:hover, #muzi .article-container:hover a.hoverColor {
  color: #91c837 !important;
}

#zeny .sectionHeader {
  border-top-color: #fe60b2 !important;
}

#zeny a.color {
  color: #fe60b2 !important;
}

#zeny .article-container {
  border-top-color: #fe60b2 !important;
}

#zeny .article-container a.hoverColor:hover, #zeny .article-container:hover a.hoverColor {
  color: #fe60b2 !important;
}

.promoBox2015.top {
  padding-bottom: 10px;
}

.promoBox2015.top.whiteBackground {
  background: #fff;
}

.promoBox2015.top .promoboxPosition-0 {
  border-top: 1px solid #c0ced5;
  border-bottom: 1px solid #c0ced5;
  width: 910px;
  margin: 17px 0;
  padding: 15px 15px 0;
  overflow: hidden;
}

.promoBox2015.top .promoboxPosition-0 .title {
  text-align: center;
  text-transform: uppercase;
  width: 910px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 72px;
  display: none;
}

.promoBox2015.top .promoboxPosition-0 .title a {
  color: #16212d;
  margin-top: 5px;
  display: block;
}

.promoBox2015.top .promoboxPosition-1 {
  width: 940px;
}

.promoBox2015.top .promoboxPosition-1 .promoLink .promoImage {
  width: 940px;
  max-height: 380px;
}

.promoBox2015.top .promoboxPosition-1A {
  float: left;
  width: 620px;
  height: 380px;
  margin-right: 10px;
}

.promoBox2015.top .promoboxPosition-1A .promoImage {
  width: 620px;
  height: 380px;
}

.promoBox2015.top .promoboxPosition-1B {
  float: left;
  width: 300px;
  height: 380px;
  margin-left: 10px;
}

.promoBox2015.top .promoboxPosition-1B .promoImage {
  width: 300px;
  height: 380px;
}

.promoBox2015.bottom .promoboxPosition-2 {
  background: #fff;
  height: 340px;
  position: relative;
}

.promoBox2015.bottom .promoboxPosition-2:hover .promoLink .title {
  color: red;
}

.promoBox2015.bottom .promoboxPosition-2:hover .promoLink .title:hover {
  text-decoration: underline;
}

.promoBox2015.bottom .promoboxPosition-2.sport:hover .promoLink .title {
  color: #91c837;
}

.promoBox2015.bottom .promoboxPosition-2.sport:hover .promoLink .title:hover {
  text-decoration: underline;
}

.promoBox2015.bottom .promoboxPosition-2.sport .header {
  color: #91c837;
}

.promoBox2015.bottom .promoboxPosition-2.zpravy:hover .promoLink .title {
  color: #083e89;
}

.promoBox2015.bottom .promoboxPosition-2.zpravy:hover .promoLink .title:hover {
  text-decoration: underline;
}

.promoBox2015.bottom .promoboxPosition-2.zpravy .header {
  color: #083e89;
}

.promoBox2015.bottom .promoboxPosition-2 .promoImage {
  width: 300px;
}

.promoBox2015.bottom .promoboxPosition-2 .header {
  color: red;
  text-transform: uppercase;
  letter-spacing: .5px;
  background-color: #fff;
  height: 12px;
  padding: 8px 15px 0;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.1;
  position: absolute;
  top: 159px;
  left: 0;
}

.promoBox2015.bottom .promoboxPosition-2 .promoLink {
  color: #16212d;
}

.promoBox2015.bottom .promoboxPosition-2 .promoLink .title {
  color: #16212d;
  z-index: 2;
  background: #fff;
  max-height: 136px;
  padding: 18px 15px 0;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 32px;
  line-height: 34px;
  position: absolute;
  top: 179px;
  left: 0;
  overflow: hidden;
}

.promoBox2015.bottom .promoboxPosition-2 .meta {
  height: 34px;
  padding-left: 15px;
  line-height: 34px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.promoBox2015.bottom .promoboxPosition-2 .meta .icon {
  float: left;
  margin-top: 12px;
}

.promoBox2015.bottom .promoboxPosition-2 .meta .photo {
  color: #c9d3df;
  margin: 0 0 0 5px;
  font-size: 12px;
}

.promoBox2015.bottom .promoboxPosition-2 .meta .value {
  color: #16212d;
  font-size: 12px;
}

.article-container, .item-middle {
  border: 0 #fff;
  overflow: hidden;
}

.article-container h3, .article-container h2, .item-middle h3, .item-middle h2 {
  height: auto;
  margin-top: 0;
  overflow: hidden;
}

.article-container h3 a, .article-container h2 a, .item-middle h3 a, .item-middle h2 a {
  color: #16212d;
  letter-spacing: 0;
  font-family: tablet_gothic_condensed;
  font-size: 30px;
  line-height: 32px;
  transition: all .2s ease-out;
}

.article-container .category-title, .item-middle .category-title {
  text-transform: uppercase;
  color: #000;
  word-spacing: 3px;
  z-index: 1;
  padding: 10px 0 0;
  font-family: tablet_gothic_condensed;
  font-size: 16px;
  line-height: 1;
  position: relative;
}

.article-container .category-title.insideImage, .item-middle .category-title.insideImage {
  text-align: right;
  width: 300px;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.article-container .category-title.insideImage > a, .article-container .category-title.insideImage > span, .article-container .category-title.insideImage > a.cat-name, .item-middle .category-title.insideImage > a, .item-middle .category-title.insideImage > span, .item-middle .category-title.insideImage > a.cat-name {
  white-space: nowrap;
  background-color: #fff;
  padding: 9px 10px 5px;
  display: inline-block;
}

.article-container.smallArticle, .item-middle.smallArticle {
  height: 330px;
  padding: 0;
}

.article-container.smallArticle .category-title, .item-middle.smallArticle .category-title {
  z-index: 1;
  text-transform: uppercase;
  letter-spacing: .5px;
  background-color: #fff;
  max-width: 110px;
  padding: 8px 10px 5px;
  font-size: 14px;
  line-height: 1.1;
  position: absolute;
  bottom: 139px;
  left: 0;
}

.article-container.smallArticle .wrapper, .item-middle.smallArticle .wrapper {
  max-height: 138px;
  padding-top: 190px;
  overflow: hidden;
}

.article-container.smallArticle .wrapper a.articlePhoto, .item-middle.smallArticle .wrapper a.articlePhoto {
  width: 300px;
  height: 180px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.article-container.smallArticle .wrapper a.articlePhoto img, .item-middle.smallArticle .wrapper a.articlePhoto img {
  width: 300px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.article-container.smallArticle .wrapper h3, .article-container.smallArticle .wrapper h2, .item-middle.smallArticle .wrapper h3, .item-middle.smallArticle .wrapper h2 {
  max-width: 300px;
  height: auto;
  min-height: 126px;
  max-height: 195px;
  margin-top: 0;
  padding: 3px 20px 0;
  font-size: 40px;
}

.article-container.smallArticle .wrapper h3 a, .article-container.smallArticle .wrapper h2 a, .item-middle.smallArticle .wrapper h3 a, .item-middle.smallArticle .wrapper h2 a {
  line-height: 35px;
  display: block;
}

.article-container.smallArticle .wrapper .articlePhoto, .item-middle.smallArticle .wrapper .articlePhoto {
  width: 280px;
  height: 158px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.article-container.smallArticle .wrapper .articleContext, .item-middle.smallArticle .wrapper .articleContext {
  margin-top: 14px;
  font-size: 12px;
  position: relative;
  bottom: 8px;
  left: 10px;
}

.article-container.smallArticle .wrapper .media-icon, .item-middle.smallArticle .wrapper .media-icon {
  z-index: 10;
  position: absolute;
  top: 155px;
  left: 135px;
}

.article-container.smallArticle.frantiska, .item-middle.smallArticle.frantiska {
  overflow: visible;
}

.article-container.smallArticle.frantiska .wrapper, .item-middle.smallArticle.frantiska .wrapper {
  height: 138px;
  padding-top: 192px;
  overflow: hidden;
}

.article-container.smallArticle.frantiska .articlePhoto, .item-middle.smallArticle.frantiska .articlePhoto {
  width: 320px;
  height: 270px;
  position: absolute;
  top: -80px;
  left: -30px;
  overflow: hidden;
}

.article-container.middleArticle, .item-middle.middleArticle {
  height: 180px;
  padding: 0;
  overflow: hidden;
}

.article-container.middleArticle .wrapper, .item-middle.middleArticle .wrapper {
  height: 180px;
  padding-left: 320px;
  overflow: hidden;
}

.article-container.middleArticle .wrapper a.articlePhoto, .item-middle.middleArticle .wrapper a.articlePhoto {
  width: 300px;
  height: 180px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.article-container.middleArticle .wrapper a.articlePhoto img, .item-middle.middleArticle .wrapper a.articlePhoto img {
  width: 300px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.article-container.middleArticle .wrapper a.articlePhotoAuto, .item-middle.middleArticle .wrapper a.articlePhotoAuto {
  width: 300px;
  height: 179px;
}

.article-container.middleArticle .wrapper a.articlePhotoAuto img, .item-middle.middleArticle .wrapper a.articlePhotoAuto img {
  width: 300px;
}

.article-container.middleArticle .wrapper h3.title, .article-container.middleArticle .wrapper h2.title, .item-middle.middleArticle .wrapper h3.title, .item-middle.middleArticle .wrapper h2.title {
  float: left;
  letter-spacing: 0;
  width: 276px;
  height: auto;
  min-height: 97px;
  padding: 20px 20px 4px 10px;
  font-size: 30px;
  line-height: 32px;
  position: relative;
}

.article-container.middleArticle .wrapper h3.title a, .article-container.middleArticle .wrapper h2.title a, .item-middle.middleArticle .wrapper h3.title a, .item-middle.middleArticle .wrapper h2.title a {
  max-height: 145px;
  padding-top: 6px;
  line-height: 29px;
  display: inline-block;
  overflow: hidden;
}

.article-container.middleArticle .wrapper h3 .titleInArtFix, .article-container.middleArticle .wrapper h2 .titleInArtFix, .item-middle.middleArticle .wrapper h3 .titleInArtFix, .item-middle.middleArticle .wrapper h2 .titleInArtFix {
  height: 0 !important;
}

.article-container.middleArticle .wrapper .articleContext, .item-middle.middleArticle .wrapper .articleContext {
  float: left;
  margin-top: 23px;
  font-size: 12px;
  line-height: 15px;
  position: relative;
  bottom: 8px;
  left: 10px;
}

.article-container.middleArticle .wrapper .category-title, .item-middle.middleArticle .wrapper .category-title {
  text-transform: uppercase;
  letter-spacing: .5px;
  padding: 20px 10px 1px;
  font-size: 14px;
  line-height: 1;
}

.article-container.middleArticle .wrapper .category-title.insideImage, .item-middle.middleArticle .wrapper .category-title.insideImage {
  padding: 0;
}

.article-container.middleArticle .wrapper .media-icon, .item-middle.middleArticle .wrapper .media-icon {
  z-index: 10;
  position: absolute;
  top: 70px;
  left: 280px;
}

.article-container.middleArticle.searchResult img, .item-middle.middleArticle.searchResult img {
  width: 300px;
  height: 180px;
  top: 0;
  left: 0;
}

.article-container.bigArticle, .item-middle.bigArticle {
  height: 390px;
  padding: 0;
  overflow: hidden;
}

.article-container.bigArticle .category-title, .item-middle.bigArticle .category-title {
  background-color: #fff;
  padding: 10px 10px 6px;
  position: absolute;
  top: 220px;
  left: 0;
}

.article-container.bigArticle .wrapper, .item-middle.bigArticle .wrapper {
  height: 180px;
  padding: 250px 0 0;
  overflow: hidden;
}

.article-container.bigArticle .wrapper .articlePhoto a, .article-container.bigArticle .wrapper .articlePhoto a.autofun, .item-middle.bigArticle .wrapper .articlePhoto a, .item-middle.bigArticle .wrapper .articlePhoto a.autofun {
  width: 620px;
  height: 240px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.article-container.bigArticle .wrapper .articlePhoto a img, .article-container.bigArticle .wrapper .articlePhoto a.autofun img, .item-middle.bigArticle .wrapper .articlePhoto a img, .item-middle.bigArticle .wrapper .articlePhoto a.autofun img {
  width: 620px;
  position: absolute;
  top: 0;
  left: 0;
}

.article-container.bigArticle .wrapper h3.title, .article-container.bigArticle .wrapper h2.title, .item-middle.bigArticle .wrapper h3.title, .item-middle.bigArticle .wrapper h2.title {
  float: left;
  letter-spacing: 0;
  height: auto;
  min-height: 100px;
  max-height: 116px;
  padding: 10px;
  position: relative;
}

.article-container.bigArticle .wrapper h3.title a, .article-container.bigArticle .wrapper h2.title a, .item-middle.bigArticle .wrapper h3.title a, .item-middle.bigArticle .wrapper h2.title a {
  font-size: 40px;
  line-height: 107%;
}

.article-container.bigArticle .wrapper .articleContext, .item-middle.bigArticle .wrapper .articleContext {
  margin-top: 10px;
  font-size: 12px;
  line-height: 15px;
  position: relative;
  bottom: 8px;
  left: 10px;
}

.article-container.bigArticle .wrapper .articleContext .forumIcon, .item-middle.bigArticle .wrapper .articleContext .forumIcon {
  position: relative;
  top: 2px;
}

.article-container.bigArticle .wrapper .media-icon, .item-middle.bigArticle .wrapper .media-icon {
  z-index: 10;
  position: absolute;
  top: 220px;
  left: 290px;
}

.item-middle h2.title a {
  line-height: 29px;
}

.xmlcolauto_paid .title {
  padding-right: 10px;
}

.article-container.littleArticle h3.title a, .article-container.littleArticle h2.title a {
  font-family: arial, sans-serif;
  font-size: 15px;
  line-height: 18px;
}

.tab-supermamy-box {
  background-color: #fff;
  margin: 20px 10px;
}

.tab-supermamy-box .box {
  float: left;
  width: 33.33%;
  height: 123px;
  position: relative;
  overflow: hidden;
}

.tab-supermamy-box .box .top3Container {
  background-color: rgba(255, 255, 255, 0);
}

.tab-supermamy-box .box .top3Container .noBorderBox {
  padding: 0;
}

.tab-supermamy-box .box .top3Container .noBorderBox .top3Soci-article {
  padding-left: 10px;
}

.tab-supermamy-box .box.jakzhubnout .noBorderBox {
  padding-left: 6px;
}

.tab-supermamy-box .box.hobby .noBorderBox {
  padding-left: 13px;
}

#weather .three-vertical {
  background: #fff;
  padding-bottom: 10px;
}

#weather .three-vertical .list-article-vertical-middle {
  padding: 20px 10px;
}

#weather .three-vertical .list-article-vertical-middle .title-container .title a {
  color: #16212d;
  font-family: tablet_gothic_condensed, sans-serif;
  font-size: 22px;
  font-weight: normal;
  line-height: 24px;
  transition: all .1s ease-out;
}

#weather .three-vertical .link {
  text-align: center;
  color: red;
  font-size: 12px;
  font-weight: bold;
  display: block;
}

#weather .three-vertical .link .dataimg-arrow-right {
  width: 5px;
  margin-left: 5px;
  display: inline-block;
}

.new-project-popup {
  z-index: 99999;
  background: #fff;
  width: 550px;
  height: 560px;
  margin-left: -290px;
  display: none;
  position: fixed;
  top: 50px;
  left: 50%;
  box-shadow: 0 0 20px rgba(0, 0, 0, .55);
}

.new-project-popup .head {
  color: #fff;
  text-align: center;
  background-color: #e81e25;
  height: 34px;
  padding: 5px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 37px;
}

.new-project-popup .head .close {
  z-index: 999999;
  text-indent: -9999px;
  cursor: pointer;
  background: url("https://img2.cncenter.cz/images/blesk-2013/close_14x14.png") no-repeat;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.new-project-popup .content_wrapper {
  background: url("https://img2.cncenter.cz/images/blesk/karlovyvary-popup.png") 50% 125px no-repeat;
  height: 414px;
}

.new-project-popup .content_wrapper:before, .new-project-popup .content_wrapper:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  display: block;
}

.new-project-popup .description {
  color: #16212d;
  text-align: center;
  width: 100%;
  margin: 20px 0 9px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 32px;
  line-height: 40px;
}

.new-project-popup .description a {
  width: 235px;
  font-size: 28px;
  display: inline-block;
}

.new-project-popup .description a:after {
  content: "";
  float: right;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -250px -215px no-repeat content-box content-box;
  width: 10px;
  height: 10px;
  padding-top: 15px;
  padding-right: 0;
  position: relative;
  left: -10px;
}

.new-project-popup .logo {
  float: left;
  background: url("https://img2.cncenter.cz/images/blesk-2013/popup-new-projects/logo_testy.png?v=1") right 20px no-repeat;
  width: 200px;
  height: 160px;
  margin: 0 auto;
  display: none;
}

.new-project-popup .goto_project {
  text-transform: uppercase;
  color: #fff;
  background: #e81e25;
  width: 172px;
  height: 33px;
  margin: 0 auto;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 39px;
  position: absolute;
  bottom: 117px;
  left: 0;
  right: 0;
}

.new-project-popup .goto_project a {
  color: #fff;
  margin-left: 17px;
}

.new-project-popup .goto_project a:hover {
  text-decoration: none;
}

.new-project-popup .goto_project:before, .new-project-popup .goto_project:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  display: block;
}

.new-project-popup .goto_project svg {
  margin: auto 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 9px;
}

.new-project-popup .popup_favor_like {
  color: #98a3af;
  text-align: center;
  border-top: 1px solid #cad5df;
  width: 504px;
  height: 101px;
  margin: 0 23px;
  overflow: visible;
}

.new-project-popup .popup_favor_like a {
  color: #98a3af;
}

.new-project-popup .popup_favor_like .popup_favor {
  float: left;
  text-align: center;
  background: url("https://img2.cncenter.cz/images/blesk-2013/popup-new-projects/favorites.png?v=1") top no-repeat;
  width: 180px;
  height: 58px;
  margin: 23px 0 0 50px;
  font-size: 13px;
}

.new-project-popup .popup_favor_like .popup_favor a {
  color: #848f9b;
  margin-top: 30px;
  font-size: 13px;
  line-height: 15px;
  display: block;
}

.new-project-popup .popup_favor_like .popup_like {
  float: right;
  text-align: center;
  background: url("https://img2.cncenter.cz/images/blesk-2013/popup-new-projects/share.png?v=1") top no-repeat;
  width: 180px;
  height: 58px;
  margin: 23px 62px 0 0;
  font-size: 13px;
}

.new-project-popup .popup_favor_like .popup_like a {
  color: #848f9b;
  margin-top: 30px;
  font-size: 13px;
  line-height: 15px;
  display: block;
}

.new-project-popup .popup_favor_like:before, .new-project-popup .popup_favor_like:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  display: block;
}

.new-project-popup .sharer {
  box-sizing: border-box;
  text-align: center;
  background: #fff;
  width: 500px;
  height: 100px;
  padding-top: 30px;
  display: none;
  position: absolute;
  bottom: 0;
  left: 40px;
}

.new-project-popup .sharebtn {
  background: url("https://img2.cncenter.cz/images/blesk/24hodin/sprites.png?v=3") 0 -65px no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.new-project-popup .like-container {
  width: 150px;
  display: inline-block;
  overflow: hidden;
}

.new-project-popup.projekt-regiony .content_wrapper {
  background: url("https://img2.cncenter.cz/images/blesk-2013/popup-new-projects/projekt-regiony-background.png?v=2") center bottom 20px no-repeat;
}

.new-project-popup.projekt-regiony .logo {
  background: url("https://img2.cncenter.cz/images/blesk-2013/popup-new-projects/projekt-regiony-logo.png?v=2") right 20px no-repeat;
  width: 224px;
  margin: 15px 0 0 40px;
}

.new-project-popup.projekt-regiony .goto_project {
  display: none;
}

.new-project-popup.projekt-regiony .description {
  width: 250px;
  padding: 36px 20px 20px 0;
  font-size: 24px;
  line-height: 21px;
}

.new-project-popup.projekt-regiony .popup_favor_like {
  border-top: 1px solid #cad5df;
  width: 486px;
  margin: 10px auto;
}

.new-project-popup.projekt-regiony .popup_favor_like a {
  font-size: 13px;
  font-weight: bold;
}

.new-project-popup.projekt-regiony .popup_favor_like .popup_favor {
  float: left;
  margin-top: 15px;
  margin-left: 90px;
}

.new-project-popup.projekt-regiony .popup_favor_like .popup_like {
  float: right;
  margin-top: 15px;
  margin-right: 90px;
}

.overlay_layer_popup {
  z-index: 9999;
  background: rgba(0, 0, 0, .5);
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.cb {
  clear: both;
}

.socialButtonsfor24hod {
  text-align: center;
}

.socialButtonsfor24hod #fb-like {
  width: 150px;
  display: inline-block;
}

.socialButtonsfor24hod #g-plus {
  width: 90px;
  display: inline-block;
}

.socialButtonsfor24hod #tweet {
  width: 80px;
  display: inline-block;
}

.article-container.littleArticle.redesigned {
  border: none;
  height: 193px;
  padding: 0;
}

.article-container.littleArticle.redesigned .wrapper {
  width: 140px;
  height: 111px;
  margin: 0;
  padding-top: 82px;
  overflow: hidden;
}

.article-container.littleArticle.redesigned .wrapper .title {
  height: 91px;
  padding: 10px;
}

.article-container.littleArticle.redesigned .wrapper .title a {
  height: 91px;
  font-family: arial, sans-serif;
  font-size: 15px;
  line-height: 18px;
  display: block;
  overflow: hidden;
}

.article-container.littleArticle.redesigned .wrapper .articlePhoto {
  width: 140px;
  height: 82px;
}

.article-container.littleArticle.redesigned .wrapper .articlePhoto img {
  width: 140px;
  height: 94px;
}

.article-container.middleArticle.redesigned {
  height: 180px;
}

.article-container.middleArticle.redesigned .wrapper .category-title {
  letter-spacing: .5px;
  padding: 20px 0 0 10px;
  font-size: 14px;
  font-family: tablet_gothic_condensed !important;
}

.article-container.middleArticle.redesigned .wrapper h3.title a {
  font-size: 30px;
  line-height: 32px;
}

.article-container.middleArticle.redesigned .wrapper .articlePhoto .articlePhotoAuto {
  width: 300px;
  height: 180px;
}

.article-container.middleArticle.redesigned .wrapper .articlePhoto .articlePhotoAuto img {
  width: 300px;
}

.article-container.middleArticle a.articlePhoto {
  width: 300px;
  height: 180px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.article-container.middleArticle a.articlePhoto img {
  width: 300px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.crossPromo .outer_4 {
  margin: 0 10px;
}

.ksHp .latestArticles {
  padding-top: 0;
}

.ksHp .category-title {
  padding-top: 3px;
}

.synot-liga-video .video-wrapper:hover .title-hidden {
  top: 25px !important;
}

.article-container {
  background-color: #fff;
  border: none;
  padding: 0 10px;
  position: relative;
}

.article-container .category-title {
  text-transform: uppercase;
  color: red;
  word-spacing: 3px;
  font-family: tablet_gothic_compressed;
  font-size: 14px;
}

.article-container .category-title a {
  color: #000;
}

.outer_4.blackBg, .outer_4.greyBg {
  margin: 10px;
}

.outer_4.blackBg a span .icon-play, .outer_4.greyBg a span .icon-play {
  position: absolute;
}

.outer_4.blackBg .linkCol, .outer_4.greyBg .linkCol {
  margin: auto;
}

.outer_4.blackBg .linkCol .videoFirstTitle, .outer_4.greyBg .linkCol .videoFirstTitle {
  float: left;
  width: 280px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 5px 0;
  font-family: tablet_gothic_condensed;
  line-height: 32px;
  overflow: hidden;
  font-size: 30px !important;
}

.outer_4.blackBg .linkCol .videoFirstTitle a, .outer_4.greyBg .linkCol .videoFirstTitle a {
  color: #fff;
  font-size: 30px !important;
}

.outer_4.greyBg {
  background-color: #232b3a;
}

.outer_4.greyBg .video-header {
  background: #232b3a;
  height: 29px;
  margin: 0;
  padding: 0;
  position: relative;
}

.outer_4.greyBg .video-header a.logoTV {
  position: absolute;
  left: 0;
}

.outer_4.greyBg .video-header .icon-tv_isport_logo {
  margin-left: 10px;
}

.outer_4.greyBg .video-header h4.header {
  margin: auto;
}

.outer_4.greyBg .video-header h4.header a {
  color: #fff;
  padding: 9px;
  font-family: tablet_gothic_condensed;
  font-size: 15px;
  position: absolute;
  right: 0;
}

.outer_4.greyBg .vertVideoBig, .outer_4.greyBg .vertVideoSmall {
  width: 300px;
  font-family: tablet_gothic_condensed;
}

.outer_4.greyBg .vertVideoBig .linkCol, .outer_4.greyBg .vertVideoSmall .linkCol {
  width: 280px;
  padding-top: 0;
  position: relative;
}

.outer_4.greyBg .vertVideoBig .linkCol:hover a, .outer_4.greyBg .vertVideoSmall .linkCol:hover a {
  text-decoration: underline;
}

.outer_4.greyBg .vertVideoBig .linkCol a span.icon-play, .outer_4.greyBg .vertVideoSmall .linkCol a span.icon-play {
  position: absolute;
}

.outer_4.greyBg .vertVideoBig .linkCol {
  border-bottom: none;
}

.outer_4.greyBg .vertVideoBig .linkCol div {
  height: 72px;
}

.outer_4.greyBg .vertVideoBig .linkCol .videoFirstPic {
  width: 280px;
  height: 158px;
  margin-bottom: 10px;
  overflow: hidden;
}

.outer_4.greyBg .vertVideoBig .linkCol .videoFirstPic a img {
  width: 280px;
}

.outer_4.greyBg .vertVideoBig .linkCol .videoFirstTitle {
  height: 120px;
  margin-bottom: 8px;
}

.outer_4.greyBg .vertVideoBig .linkCol .videoFirstTitle a {
  font-size: 30px;
  line-height: 32px;
}

.outer_4.greyBg .vertVideoBig .linkCol .watched-big {
  float: right;
  width: 60px;
  height: 17px;
}

.outer_4.greyBg .vertVideoBig .linkCol .watched-big .icon-eye {
  margin-top: 2px;
  position: absolute;
}

.outer_4.greyBg .vertVideoBig .linkCol .watched-big span.numOfWatch {
  float: right;
  text-align: right;
  color: #fff;
  margin-right: 10px;
  font-family: arial;
  font-size: 12px;
  display: block;
}

.outer_4.greyBg .vertVideoBig .linkCol a span.icon-play {
  margin: auto;
  top: 128px;
  left: 4%;
  right: 4%;
}

.outer_4.greyBg .vertVideoSmall {
  float: left;
}

.outer_4.greyBg .vertVideoSmall .linkCol {
  border-top: 1px solid rgba(255, 255, 255, .2);
  border-bottom: none;
  height: 73px;
  padding: 10px 0;
}

.outer_4.greyBg .vertVideoSmall .linkCol .videoOtherTitle {
  float: left;
  width: 150px;
  height: 68px;
  margin-left: 10px;
  line-height: 17px;
}

.outer_4.greyBg .vertVideoSmall .linkCol .videoOtherTitle:hover a {
  text-decoration: underline;
}

.outer_4.greyBg .vertVideoSmall .linkCol .videoOtherTitle a {
  color: #fff;
  font-family: arial;
  font-size: 15px;
  font-weight: 700;
}

.outer_4.greyBg .vertVideoSmall .linkCol a img {
  float: left;
  width: 120px;
  max-height: 72px;
}

.outer_4.greyBg .vertVideoSmall .linkCol a span.icon-play {
  top: 63px;
  left: 47px;
}

.outer_4.blackBg {
  background-color: #232b3a;
  margin: 20px 10px 0;
}

.outer_4.blackBg .vertVideo .top {
  background: #232b3a;
  height: 40px;
  margin: 0;
  padding: 0;
  position: relative;
}

.outer_4.blackBg .vertVideo .top a.logo_TVBlesk {
  position: absolute;
  top: 0;
  left: 0;
}

.outer_4.blackBg .paidPlaceholder-new .linkCol {
  width: 300px;
  margin: 0;
  padding-top: 0;
  position: relative;
}

.outer_4.blackBg .paidPlaceholder-new .linkCol:hover .videoFirstTitle a {
  text-decoration: underline;
}

.outer_4.blackBg .paidPlaceholder-new .linkCol .videoFirstPic {
  width: 300px;
  height: 158px;
  margin-bottom: 15px;
  overflow: hidden;
}

.outer_4.blackBg .paidPlaceholder-new .linkCol .videoFirstPic a img {
  width: 300px;
}

.outer_4.blackBg .paidPlaceholder-new .linkCol .videoFirstTitle {
  height: 120px;
  margin: 0 10px 8px;
  padding: 5px 0;
}

.outer_4.blackBg .paidPlaceholder-new .linkCol #category-title {
  letter-spacing: .5px;
  text-transform: uppercase;
  color: #fff;
  background-color: #232b3a;
  max-width: 110px;
  height: auto;
  padding: 8px 10px 5px;
  font-family: tablet_gothic_condensed;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.1;
  display: block;
  position: absolute;
  bottom: 144px;
}

.outer_4.blackBg .paidPlaceholder-new .linkCol a span.icon-play {
  margin: auto;
  position: absolute;
  top: 132px;
  left: 4%;
  right: 4%;
}

.tv-carousel-container * {
  box-sizing: border-box;
}

.tv-carousel-container {
  float: left;
  width: 300px;
  height: 100%;
  margin: 0 10px 20px;
  display: block;
  position: relative;
}

.tv-carousel-container #premiere-today-title {
  letter-spacing: .5px;
  color: red;
  background-color: #232b3a;
  width: 300px;
  padding: 10px;
  font-family: tablet_gothic_condensed;
  font-size: 14px;
}

.tv-carousel-container .owl-carousel {
  z-index: 0;
}

.tv-carousel-container .today-butt-box {
  cursor: pointer;
  width: 30px;
  height: 20px;
  position: absolute;
  right: 35px;
}

.tv-carousel-container .today-butt-box .today-butt {
  color: #b5c7e5;
  font-size: 12px;
  text-decoration: underline;
  position: absolute;
  top: 5px;
}

.tv-carousel-container .today-butt-box .today-butt:after {
  content: "";
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: inset 40px 0 50px -70px #0e1520, inset -40px 0 50px -40px #0e1520;
}

.tv-carousel-container .carousel-control {
  width: 27px;
  margin-top: -20px;
  position: absolute;
  top: 50%;
}

.tv-carousel-container .carousel-control.tv-carousel-next {
  right: -40px;
}

.tv-carousel-container .carousel-control.tv-carousel-prev {
  left: -40px;
}

.tv-carousel-container .carousel-control .caret-prev {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: url("/images/blesk-tv/caret_left.png");
  width: 27px;
  height: 51px;
  display: inline-block;
}

.tv-carousel-container .carousel-control .caret-next {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: url("/images/blesk-tv/caret_right.png");
  width: 27px;
  height: 51px;
  display: inline-block;
}

.tv-carousel-container .tv-carousel {
  float: left;
  width: 100%;
  height: auto;
  margin: 0;
  position: relative;
  overflow: hidden;
}

.tv-carousel-container .tv-carousel .owl-item.active:last-child .day-container {
  border-right: none;
}

.tv-carousel-container .tv-carousel .owl-stage-outer .owl-stage {
  margin-top: 20px;
}

.tv-carousel-container .tv-carousel .day-container {
  background: #232b38;
  border-right: 1px dotted #5d6b84;
  max-width: 300px;
  margin-top: 0;
  position: relative;
}

.tv-carousel-container .tv-carousel .day-container .day-placeholder {
  color: #b5c7e5;
  text-transform: uppercase;
  background: #232b38;
  width: 100%;
  padding: 12px 10px 8px;
  font-size: 12px;
  display: inline-block;
}

.tv-carousel-container .tv-carousel .day-container .day-placeholder .left {
  float: left;
  text-align: left;
  width: 60%;
  display: inline-block;
}

.tv-carousel-container .tv-carousel .day-container .day-placeholder .right {
  float: left;
  text-align: right;
  width: 40%;
  display: inline-block;
}

.tv-carousel-container .tv-carousel .day-container .day-program {
  width: 100%;
  margin: 0;
  padding: 0;
  display: block;
}

.tv-carousel-container .tv-carousel .day-container .day-program li {
  width: 100%;
  height: 100%;
  min-height: 55px;
  margin: 0;
  display: block;
}

.tv-carousel-container .tv-carousel .day-container .day-program li a {
  background: none;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 55px;
  padding: 5px 10px;
  transition: background .5s ease-out;
  display: block;
}

.tv-carousel-container .tv-carousel .day-container .day-program li a:hover {
  background: #4e5869;
  transition: background .5s ease-in;
}

.tv-carousel-container .tv-carousel .day-container .day-program li a:hover .picture .hover-play {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.tv-carousel-container .tv-carousel .day-container .day-program li a .info {
  float: left;
  width: 83%;
}

.tv-carousel-container .tv-carousel .day-container .day-program li a .info span {
  width: 100%;
  display: inline-block;
}

.tv-carousel-container .tv-carousel .day-container .day-program li a .info span.time {
  color: #b5c7e5;
  text-transform: uppercase;
  margin-bottom: 3px;
  font-size: 11px;
}

.tv-carousel-container .tv-carousel .day-container .day-program li a .info span.name {
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
}

.tv-carousel-container .tv-carousel .day-container .day-program li a .picture {
  float: left;
  width: 35px;
  height: 35px;
  margin: 5px 4px;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.tv-carousel-container .tv-carousel .day-container .day-program li a .picture .hover-play {
  opacity: 0;
  z-index: 100;
  background: rgba(22, 30, 42, .5);
  border-radius: 100%;
  width: 100%;
  height: 100%;
  transition: opacity .5s ease-out;
  position: absolute;
  top: 0;
  left: 0;
}

.tv-carousel-container .tv-carousel .day-container .day-program li a .picture .hover-play .icon {
  z-index: 100;
  margin-top: -9px;
  margin-left: -9px;
  position: absolute;
  top: 52%;
  left: 52%;
}

.tv-carousel-container .tv-carousel .day-container .day-program li a .picture img {
  border-radius: 100%;
  width: 100%;
  height: 100%;
}

.tv-carousel-container .tv-carousel .day-container .day-program li:nth-child(2n) {
  background: #232b39;
}

.tv-carousel-container .tv-carousel .day-container .day-program li:nth-child(odd) {
  background: #161e2a;
}

.browsehappy {
  color: #000;
  background: #ccc;
  margin: .2em 0;
  padding: .2em 0;
}

.next-broadcast-info {
  float: left;
  border-top: 2px solid #232b39;
  width: 100%;
  max-width: 940px;
  margin: 0 10px;
  padding: 20px 0;
  font-size: 20px;
}

.next-broadcast-info .line {
  background: none;
  height: auto;
  padding: 5px 0;
}

.next-broadcast-info .line .banner-text {
  color: #fff;
}

.next-broadcast-info .line .broadcast-name {
  color: #b5c7e5;
  text-transform: uppercase;
}

.next-broadcast-info .line .date {
  color: #b5c7e5;
}

.next-broadcast-info .line .prev-broadcasts-text {
  color: #b5c7e5;
  font-size: 18px;
}

.modniPolicie .article-container {
  background-color: #16212d;
  border-top: none;
  height: 676px;
  position: relative;
}

.modniPolicie .article-container h3 {
  width: 270px;
  margin-top: 20px;
  padding-top: 10px;
  padding-right: 10px;
  position: relative;
}

.modniPolicie .article-container h3 a {
  height: 120px;
  font-family: tablet_gothic_condensed;
  font-size: 30px;
  line-height: 30px;
  transition: all .1s ease-out;
  display: block;
  color: #c0ced5 !important;
}

.modniPolicie .article-container h3 a:hover {
  color: #00c8be !important;
}

.modniPolicie .article-container .category-title {
  background-color: #16212d;
  padding: 8px 10px 5px 0;
  position: absolute;
  top: 502px;
}

.modniPolicie .article-container .category-title a {
  color: #00c8be !important;
}

.modniPolicie .article-container .imageTop {
  background-color: rgba(0, 0, 0, 0);
  width: 280px;
  height: 510px;
  margin: auto;
  padding-top: 10px;
  display: block;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
}

.article-container.bigOneArticle {
  padding: 0;
  height: auto !important;
}

.article-container.bigOneArticle h3.title.bg {
  float: left;
  width: 320px;
  height: 328px;
  margin: 0;
  padding: 0;
  font-family: tablet_gothic_condensed;
  font-size: 50px;
  line-height: 52px;
  display: block;
  position: relative;
  overflow: hidden;
  background-color: #fff !important;
}

.article-container.bigOneArticle h3.title.bg a {
  color: #000;
  width: 280px;
  height: auto;
  min-height: 252px;
  padding: 25px 10px 0;
  font-size: 40px;
  font-weight: 400;
  line-height: 118%;
  display: block;
  position: relative;
  overflow: hidden;
}

.article-container.bigOneArticle h3.title.bg a:hover {
  color: red;
}

.article-container.bigOneArticle h3.title.bg a:hover span {
  text-decoration: underline;
}

.article-container.bigOneArticle h3.title.bg .category-title {
  height: 25px;
  padding: 0;
  color: #fff !important;
}

.article-container.bigOneArticle h3.title.bg .category-title a {
  color: red;
  min-height: 17px;
  padding: 10px 10px 0;
  font-size: 16px;
}

.article-container.bigOneArticle h3.title.bg .articleContext {
  color: #98a3af;
  float: left;
  width: auto;
  height: 14px;
  margin-top: 0;
  padding: 5px 10px 10px;
  font-family: arial;
  line-height: 15px;
  position: static;
  bottom: 10px;
  left: 10px;
}

.article-container.bigOneArticle h3.title.bg .articleContext span {
  float: left;
  color: #232b3a;
  padding: 0 2px;
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
}

.article-container.bigOneArticle h3.title.bg .articleContext span a {
  padding: 0;
}

.article-container.bigOneArticle h3.title.bg .articleContext span.forumTitle:hover, .article-container.bigOneArticle h3.title.bg .articleContext span.galleryTitle:hover {
  color: #232b3a !important;
}

.article-container.bigOneArticle h3.title.bg .articleContext span.forumTitle a, .article-container.bigOneArticle h3.title.bg .articleContext span.galleryTitle a {
  color: #98a3af;
  padding: 0;
  font-family: arial;
  font-size: 12px;
  display: inline;
}

.article-container.bigOneArticle h3.title.bg .articleContext span.forumTitle a:hover, .article-container.bigOneArticle h3.title.bg .articleContext span.galleryTitle a:hover {
  color: #232b3a !important;
}

.article-container.bigOneArticle h3.title.bg .articleContext span.forumCount, .article-container.bigOneArticle h3.title.bg .articleContext span.galleryCount {
  color: #232b3a;
}

.article-container.bigOneArticle h3.title.bg .articleContext .forumIcon, .article-container.bigOneArticle h3.title.bg .articleContext .galleryIcon {
  display: inline-block;
  position: relative;
}

.container .grid_12 .fb_autocz_banner {
  float: left;
  margin-top: 15px;
  position: relative;
}

.system .article-container.smallArticle .wrapper .articleContext {
  left: 10px !important;
}

.system .xmlcolauto_paid .item.article-container.last {
  border-bottom: none;
}

.system .xmlcolauto_paid .item.article-container {
  border-bottom: 1px solid #cad5df;
  height: 66px;
  padding: 10px 0 11px;
  position: relative;
  overflow: visible;
  background-color: rgba(0, 0, 0, 0) !important;
  border-top: 0 !important;
}

.system .xmlcolauto_paid .item.article-container .title {
  float: left;
  width: 150px;
  margin-left: 0;
}

.system .xmlcolauto_paid .item.article-container .title a {
  color: #16212d;
  font-family: arial;
  font-size: 15px;
  font-weight: 700;
}

.system .xmlcolauto_paid .item.article-container .image a {
  width: 120px;
  height: 68px;
  display: block;
  position: absolute;
  right: 0;
  overflow: hidden;
}

.system .article-container.middleArticle .wrapper .articleContext, .system .article-container.smallArticle .wrapper .articleContext {
  color: #c9d3df;
  margin-top: 10px;
  font-size: 12px;
  line-height: 15px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  left: 10px !important;
}

.system .article-container.middleArticle .wrapper .articlePhotoAuto, .system .article-container.smallArticle .wrapper .articlePhotoAuto {
  width: 300px;
  height: 180px;
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.system .article-container.middleArticle .wrapper a img, .system .article-container.smallArticle .wrapper a img {
  width: 300px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.system .article-container.middleArticle .wrapper .category-title a, .system .article-container.smallArticle .wrapper .category-title a, .system .article-container.middleArticle .wrapper h3.title a:hover, .system .article-container.smallArticle .wrapper h3.title a:hover {
  color: red;
}

.recentArticles.outer_4 h4.header {
  text-transform: uppercase;
  color: #fff;
  background-color: red;
  height: 35px;
  margin-bottom: 0;
  padding-left: 10px;
  font-family: tablet_gothic_condensed;
  font-size: 16px;
  line-height: 35px;
}

.recentArticles.outer_4 h4.header a {
  color: #fff;
  display: block;
}

.recentArticles.outer_4 .rec_vertical_paid .item.article-container.first {
  padding-top: 10px;
}

.recentArticles.outer_4 .rec_vertical_paid .item.article-container.last {
  border-bottom: none;
}

.recentArticles.outer_4 .rec_vertical_paid .item.article-container {
  border-bottom: 1px solid #cad5df;
  height: 70px;
  padding: 10px 0 11px;
  position: relative;
  overflow: visible;
  background-color: rgba(0, 0, 0, 0) !important;
  border-top: 0 !important;
}

.recentArticles.outer_4 .rec_vertical_paid .item.article-container .title {
  float: left;
  width: 165px;
  margin-left: 0;
}

.recentArticles.outer_4 .rec_vertical_paid .item.article-container .title a {
  color: #16212d;
  font-family: arial;
  font-size: 15px;
  font-weight: 700;
  transition: all .1s ease-out;
}

.recentArticles.outer_4 .rec_vertical_paid .item.article-container .image {
  float: left;
  width: 120px;
  position: absolute;
  right: 0;
  overflow: hidden;
}

.recentArticles.outer_4 .rec_vertical_paid .item.article-container .image a.iSport {
  width: 120px;
  height: 68px;
  display: block;
  position: relative;
  overflow: hidden;
}

.recentArticles.outer_4 .rec_vertical_paid .item.article-container .image a.iSport img {
  width: 120px;
  height: 80px;
}

.recentArticles.outer_4 .rec_vertical_paid .item.article-container .image a img {
  width: 120px;
  height: 68px;
}

.recentArticles.outer_4 .footer.more {
  margin: 10px 0;
}

.heightFix .recentArticles .rec_vertical_paid .item.article-container.first {
  padding: 20px 0 10px;
}

.blackBackground {
  float: left;
  position: relative;
  top: -10px;
}

.blackBackground .top3Container {
  background-color: #000;
}

.blackBackground .top3Container .inner_4 .top3Soci-article h3 a {
  color: #fff;
}

.blackBackground .top3Container .noBorderBox.position-3 {
  margin-left: 30px;
}

.sectionHeader .blesk24logorubrikaHP {
  position: absolute;
  top: 10px;
  left: 126px;
}

.synot-liga-video {
  margin-top: 10px;
  margin-bottom: 10px;
}

.linkArticle.article-container .wrapper {
  height: 180px;
  padding-left: 320px;
  overflow: hidden;
  margin-top: 0 !important;
}

.linkArticle.article-container .wrapper a.articlePhoto {
  width: 300px;
  height: 180px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.linkArticle.article-container .wrapper h3.title {
  float: left;
  letter-spacing: 0;
  width: 290px;
  padding: 24px 20px 4px 10px;
  font-size: 30px;
  line-height: 32px;
  position: relative;
  overflow: visible;
  top: -8px !important;
}

.linkArticle.article-container .content .articleContext {
  color: #c9d3df;
  margin-top: 15px;
  font-family: arial, sans-serif;
  font-size: 11px;
  line-height: 15px;
  position: relative;
  bottom: 8px;
  left: 20px;
}

.top3-presentstips {
  background-color: #b00;
  margin-top: 20px;
}

.top3-presentstips a.title {
  color: #fff;
  padding: 5px 10px;
  font-family: arial;
  font-size: 14px;
  position: absolute;
  left: 0;
}

.top3-presentstips a.title:hover {
  color: #000;
}

.top3-presentstips .inner_4 {
  width: 313px;
  margin-bottom: 5px;
  padding: 22px 0 0 7px;
}

.top3-presentstips .inner_4:hover h3 a {
  color: #000 !important;
}

.top3-presentstips .inner_4:first-of-type {
  padding-left: 10px;
}

.top3-presentstips .inner_4 img {
  float: left;
  position: relative;
}

.top3-presentstips .inner_4 h3 {
  float: left;
  border-bottom: 1px solid #ff8a8a;
  width: 138px;
  min-height: 67px;
  margin-left: 5px;
}

.top3-presentstips .inner_4 h3 a {
  color: #fff;
  font-size: 14px;
  line-height: 122%;
}

.top3-presentstips .inner_4 h3 a:hover {
  color: #000;
}

.top3-presentstips .inner_4 .cena {
  color: #fff;
  float: left;
  width: 77px;
  padding: 5px 0 5px 5px;
  position: relative;
}

.top3-presentstips .inner_4 a.buy {
  color: #fff;
  float: left;
  padding: 5px 0;
  font-weight: 700;
  text-decoration: underline;
  position: relative;
}

.top3-presentstips .inner_4 a.buy:hover {
  color: #000;
}

.forumBoxReturn .article-container.middleArticle .wrapper {
  margin: 0;
}

.forumBoxReturn .article-container.middleArticle .wrapper h3.title {
  max-height: 160px;
  padding: 18px 20px 4px 10px;
}

.relatedArticles .article {
  float: left;
  width: 100%;
  padding: 10px 0;
  position: relative;
}

.relatedArticles .article .wrapper {
  height: 180px;
  overflow: hidden;
}

.relatedArticles .article .wrapper span.icon {
  position: absolute;
  top: 70px;
  left: 280px;
  margin: 0 !important;
}

.relatedArticles .article .wrapper h3.title, .relatedArticles .article .wrapper a.title {
  float: left;
  letter-spacing: 0;
  height: auto;
  min-height: 110px;
  padding: 24px 20px 4px 10px;
  font-size: 30px;
  line-height: 32px;
  position: relative;
  width: 270px !important;
}

.relatedArticles .article .wrapper a.title {
  display: block;
}

.relatedArticles .article a {
  width: 270px;
}

.relatedArticles .article a img {
  width: auto !important;
  height: auto !important;
}

.relatedArticles .article.last {
  border-bottom: none !important;
}

.cont-24h-HP {
  float: left;
  background-color: #fff;
  width: 620px;
  margin: 10px;
  position: relative;
}

.cont-24h-HP .header24 {
  height: 40px;
  position: relative;
}

.cont-24h-HP .header24 a.icon-logo24_inverze {
  float: left;
  margin-left: 10px;
  position: relative;
}

.cont-24h-HP .header24 a.nws-more-news {
  color: red;
  text-align: right;
  width: auto;
  margin: 8px 20px 8px 10px;
  padding: 0;
  font-family: tablet_gothic_condensed;
  font-size: 20px;
  line-height: 158%;
  position: absolute;
  right: 0;
}

.cont-24h-HP .header24 .grey-line {
  float: left;
  background-color: #cad5df;
  width: 552px;
  height: 1px;
  position: absolute;
  bottom: 0;
  right: 8px;
}

.cont-24h-HP .nws-list {
  width: 620px;
  height: 17px;
  margin: 5px 0;
}

.cont-24h-HP .nws-list .nws-ts {
  color: #98a3af;
  float: left;
  margin: 3px 18px 0 10px;
  font-size: 13px;
  position: relative;
}

.cont-24h-HP .nws-list a.nws24-title {
  color: #000;
  float: left;
  padding-right: 5px;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2em;
  position: relative;
}

.cont-24h-HP .nws-list a.nws24-title:hover {
  color: red;
}

.cont-24h-HP .nws-list a.nws24-title:before, .cont-24h-HP .nws-list a.nws24-title:after {
  content: " ";
  pointer-events: none;
  border: solid rgba(0, 0, 0, 0);
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: 100%;
}

.cont-24h-HP .nws-list a.nws24-title:before {
  border-width: 11px;
  border-color: rgba(0, 0, 0, 0);
  margin-top: -11px;
}

.cont-24h-HP .nws-list a.nws24-title:after {
  border-width: 5px;
  border-color: rgba(255, 0, 0, 0) rgba(255, 0, 0, 0) rgba(255, 0, 0, 0) #000;
  margin-top: -5px;
}

.cont-24h-HP .nws-list a.nws24-title:hover:after {
  border-left-color: red;
}

.middleArticles {
  position: relative;
  left: -90px;
}

.latestArticles {
  margin-bottom: 0;
}

.latestArticles .categoryArrow .arrow {
  background: none;
}

.toparticles-vert.outer_4 {
  margin-bottom: 10px;
}

.toparticles-vert.outer_4 .top_vertical_paid .item.article-container {
  background-color: rgba(0, 0, 0, 0);
  border-top: 0;
  border-bottom: 1px solid #cad5df;
  padding: 10px 0 9px;
  position: relative;
}

.toparticles-vert.outer_4 .top_vertical_paid .item.article-container .image {
  float: left;
  width: 120px;
  height: 68px;
  overflow: hidden;
}

.toparticles-vert.outer_4 .top_vertical_paid .item.article-container .title {
  float: right;
  width: 155px;
  padding-right: 15px;
}

.toparticles-vert.outer_4 .top_vertical_paid .item.article-container .title a {
  color: #16212d;
  font-family: tablet_gothic_condensed;
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
  transition: all .1s ease-out;
}

.toparticles-vert.outer_4 .top_vertical_paid .item.article-container.article-container--noBorderBottom {
  border-bottom: 0;
}

.toparticles-vert.outer_4 .categoryArrow .arrow {
  background: none;
}

.maminka-logo {
  background: url("/images/blesk/dist/svg/logo-maminka.svg") 0 0 / 170px 30px no-repeat;
  width: 170px;
  height: 30px;
  margin-top: 30px;
  display: block;
}

.sportVertical .recentArticles .iconImage {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -300px -450px no-repeat content-box content-box;
  border-top: 0 #cad5df;
  border-bottom: 0 #cad5df;
  height: 52px;
  margin-bottom: 0;
  padding-top: 2px;
  padding-bottom: 0;
  padding-left: 87px;
  display: block;
}

.sportVertical .recentArticles .isport-logo-top {
  text-align: center;
  height: 42px;
  padding-top: 2px;
  display: block;
}

.sportVertical .recentArticles .isport-logo-top .icon {
  display: inline-block;
}

.sportVertical.article-border-bottom .recentArticles.outer_4 .rec_vertical_paid .item.article-container.last {
  border-bottom: 1px solid #cad5df;
}

.galleryInArticle {
  background: #16212d;
  padding: 10px;
}

.galleryInArticle.onlineReport {
  margin-left: -140px !important;
}

.galleryInArticle .header {
  padding: 0 40px 10px;
}

.galleryInArticle .header a {
  text-transform: uppercase;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
}

.galleryInArticle .header .galleryCount {
  float: right;
  color: #e9eff4;
  font-size: 15px;
  line-height: 18px;
  display: inline-block;
}

.galleryInArticle .header .title {
  color: #fff;
  padding: 0 5px;
}

.galleryInArticle .description {
  color: #e9eff4;
  padding: 10px 40px;
  font-size: 12px;
  line-height: 15px;
}

.galleryInArticle .imgAuthor {
  color: #e9eff4;
  margin: 0 40px;
  font-size: 12px;
  line-height: 15px;
}

.galleryInArticle .smallPhoto {
  position: relative;
}

.galleryInArticle .smallPhoto .photoIn {
  float: left;
  margin: 0 0 0 20px;
  position: relative;
}

.galleryInArticle .smallPhoto .photoIn img {
  width: 158px;
  height: 100px;
}

.galleryInArticle .smallPhoto .galleryPrev {
  text-indent: 9999px;
  background: #16212d url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -50px -232px no-repeat content-box content-box;
  border-left: 1px solid #3d4952;
  width: 10px;
  height: 10px;
  margin: 5px 0 0;
  padding: 40px 0 40px 10px;
  display: block;
  position: relative;
  top: 0;
  transform: rotate(180deg);
}

.galleryInArticle .smallPhoto .galleryNext {
  text-indent: 9999px;
  float: left;
  background: #16212d url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -50px -216px no-repeat content-box content-box;
  border-left: 1px solid #98a3af;
  width: 10px;
  height: 10px;
  margin: 5px 0 0;
  padding: 40px 0 40px 10px;
  display: block;
  position: relative;
  top: 0;
  right: 5px;
}

.galleryInArticle .photoFrame {
  background-color: #16212d;
}

.exchange-rate-list {
  color: #707b87;
  text-align: center;
  border: 1px solid #cad5df;
  align-items: center;
  width: 316px;
  height: 30px;
  line-height: 30px;
  display: flex;
  position: relative;
}

.exchange-rate-list:before, .exchange-rate-list:after {
  content: " ";
  visibility: hidden;
  clear: both;
  width: 0;
  height: 0;
  display: block;
  overflow: hidden;
}

.exchange-rate-list a {
  color: #707b87;
  text-align: center;
  align-items: center;
  width: 80px;
  height: 30px;
  line-height: 30px;
  display: inline-flex;
}

.exchange-rate-list a img {
  margin-left: 2px;
  margin-right: 4px;
}

.exchange-rate-list .title {
  text-transform: uppercase;
  width: 72px;
  margin: 3px 7px 0 6px;
  font-family: tablet_gothic_condensed;
  font-size: 16px;
}

.exchange-rate-list .rate {
  font-size: 12px;
}

.exchange-rate-list .currency {
  display: none;
}

img {
  max-width: 100%;
}

.kw .articleContainer .relativeObject {
  margin-left: 0;
}

.box-gallery {
  float: left;
  width: 620px;
  height: 328px;
}

.box-gallery .gallery-main-container .image-description {
  display: none;
}

.box-gallery .gallery-main-container .gallery {
  height: 100%;
}

.box-gallery .gallery-main-container .gallery .icon-loading {
  background: url("https://img2.cncenter.cz/images/blesk-2013/loading.svg?v=1") center no-repeat;
}

.box-gallery .gallery-main-container .gallery .btn {
  height: 100%;
  top: 0;
}

.box-gallery .gallery-main-container .gallery .btn.prev .dataimg-arrow-light-left {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -950px -450px no-repeat;
}

.box-gallery .gallery-main-container .gallery .btn.next .dataimg-arrow-light-right {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -1000px -450px no-repeat;
}

.box-gallery .gallery-main-container .gallery .btn .icon-arrow {
  border-left: 5px solid rgba(0, 0, 0, 0);
}

.box-gallery .gallery-main-container .gallery .next .image-count {
  display: none;
}

.box-gallery .gallery-main-container .gallery .images-container .owl-item img {
  left: 0;
  right: 0;
}

#article .topGallery {
  margin-bottom: 20px;
}

#article .topGallery .photoCarousel {
  width: 100%;
  margin-bottom: 10px;
  display: inline-block;
}

#article .topGallery .photoCarousel .jcarousel {
  padding: 0;
}

#article .topGallery .photoCarousel .jcarousel ul.jcarouselList {
  list-style-type: none !important;
}

#article.listed .photoCarousel {
  width: 100%;
  margin: 10px 0;
  display: inline-block;
  position: relative;
  left: 0;
}

#article.listed .photoCarousel a.jcarousel-prev, #article.listed .photoCarousel a.jcarousel-next {
  background: #16212d url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -50px -216px no-repeat content-box content-box;
  border-left: 1px solid #98a3af;
  top: 0;
}

#article.listed .photoCarousel .jcarousel {
  width: 540px;
  margin: 0;
  padding: 0;
  left: 40px;
}

#article.listed .photoCarousel .jcarousel ul.jcarouselList {
  list-style-type: none !important;
}

#article.listed .photoCarousel .jcarousel ul li img {
  margin: 0 10px;
}

#article .recommended .list-article--horizontal-small {
  margin: 0 0 10px;
}

#article .recommended .list-article--horizontal-small .list-article__category-name {
  text-transform: none;
}

#article .recommended .list-article .list-article__title:hover {
  color: #000;
}

.breadcrumbs {
  float: left;
  width: 940px;
  margin: 15px 10px;
  position: relative;
}

.breadcrumbs ol {
  width: 100%;
  height: 15px;
  position: relative;
}

.breadcrumbs ol li {
  float: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 340px;
  height: 15px;
  margin: 0 10px 0 0;
  line-height: 15px;
  display: inline-block;
  overflow: hidden;
}

.breadcrumbs ol li a {
  color: #aaa;
}

.breadcrumbs ol li a:hover {
  text-decoration: underline;
}

.breadcrumbs ol li:last-of-type a, .breadcrumbs ol li:last-of-type span {
  font-weight: bold;
}

.breadcrumbs ol li span {
  color: #aaa;
  font-size: 13px;
}

.breadcrumbs ol .arrow-icon {
  float: left;
  width: 5px;
  height: 9px;
  margin: 2px 10px 0 0;
  display: inline-block;
  position: relative;
}

.dataimg-arrow-right {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 5 9' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23AAAAAA' d='M5,4.3L1.3,0L0,0.7l3.1,3.7L0,8.2L1.3,9L5,4.6V4.3z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}

.br-info {
  box-sizing: border-box;
  float: left;
  background-color: #fff;
  width: 300px;
  margin: 10px 10px 20px;
  padding: 15px 20px 20px;
}

.br-info .br-info-header {
  float: left;
  border-bottom: 1px solid #ccc;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 10px;
  position: relative;
}

.br-info .br-info-header .br-info-title {
  text-transform: uppercase;
  color: red;
  word-spacing: 1px;
  float: left;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 14px;
  position: relative;
}

.br-info .br-info-logo {
  width: 11px;
  display: block;
  position: absolute;
  top: -10px;
  right: 0;
}

.br-info .br-info-content {
  float: left;
  font-size: 15px;
  line-height: 1.2em;
  position: relative;
}

.br-info .br-info-content ul {
  float: left;
  width: 100%;
  margin-top: 20px;
  position: relative;
}

.br-info .br-info-content ul li {
  float: left;
  border-right: 1px solid #aaa;
  margin-right: 14px;
  padding-right: 14px;
  font-weight: bold;
  line-height: 15px;
  position: relative;
}

.br-info .br-info-content ul li:last-child {
  float: left;
  border-right: none;
  margin-right: 0;
  padding-right: 0;
  position: relative;
}

.br-info .br-info-content ul li a {
  color: red;
  font-size: 12px;
}

.component-menu-category {
  width: 940px;
  height: 78px;
  margin: 0 10px 10px;
  position: relative;
}

.component-menu-category .cmc-title {
  color: #fff;
  height: 75px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 50px;
  line-height: 88px;
  position: absolute;
  top: 0;
  left: 30px;
}

.component-menu-category .cmc-title a {
  color: #fff;
}

.component-menu-category .cmc-container {
  float: right;
  height: 25px;
  margin: 27px 20px 26px 0;
}

.component-menu-category .cmc-container .cmc-box {
  float: left;
  height: 25px;
  padding-bottom: 10px;
  position: relative;
}

.component-menu-category .cmc-container .cmc-box:hover .cmc-list {
  display: block;
}

.component-menu-category .cmc-container .cmc-box .cmc-link {
  color: #fff;
  float: left;
  text-transform: uppercase;
  border-right: 1px solid #fff;
  height: 25px;
  margin-right: 20px;
  padding-right: 20px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 30px;
  display: block;
}

.component-menu-category .cmc-container .cmc-box .cmc-link .cmc-arrow {
  float: right;
  margin: 10px 0 0 7px;
  display: block;
}

.component-menu-category .cmc-container .cmc-box:last-of-type .cmc-link {
  border-right: none;
  margin-right: 0;
  padding-right: 0;
}

.component-menu-category .cmc-container .cmc-box:last-of-type .cmc-list {
  left: auto;
  right: -20px;
}

.component-menu-category .cmc-container .cmc-box .cmc-list {
  box-sizing: border-box;
  z-index: 9999;
  background-color: #16212d;
  padding: 10px 20px;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.component-menu-category .cmc-container .cmc-box .cmc-list .cmc-link {
  color: #e9eff4;
  float: left;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  border-right: none;
  width: 100%;
  height: 29px;
  margin-right: 0;
  padding-right: 0;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 33px;
  display: block;
  overflow: hidden;
}

.component-menu-category.testy, .component-menu-category.celebrity {
  background: red;
  border-top-color: #540101;
}

.component-menu-category.testy .cmc-title a, .component-menu-category.celebrity .cmc-title a {
  color: #fff !important;
}

.component-menu-category.testy .cmc-container .cmc-box:last-child > a, .component-menu-category.celebrity .cmc-container .cmc-box:last-child > a {
  margin-right: 10px !important;
}

.component-menu-category.testy .cmc-container .cmc-box:last-child > a:after, .component-menu-category.celebrity .cmc-container .cmc-box:last-child > a:after {
  top: 18px;
  right: 7px;
  background: url("//img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

.component-menu-category.testy .cmc-container .cmc-box:last-child > a.noarrow, .component-menu-category.celebrity .cmc-container .cmc-box:last-child > a.noarrow {
  margin-right: 0 !important;
}

.component-menu-category.testy .cmc-container .cmc-box:last-child > a.noarrow:after, .component-menu-category.celebrity .cmc-container .cmc-box:last-child > a.noarrow:after {
  content: none;
}

.component-menu-category.testy .cmc-container .cmc-box .cmc-link, .component-menu-category.celebrity .cmc-container .cmc-box .cmc-link {
  color: #fff !important;
}

.component-menu-category.testy .cmc-container .cmc-box .cmc-list, .component-menu-category.celebrity .cmc-container .cmc-box .cmc-list {
  background: red !important;
}

.component-menu-category.testy .cmc-container .cmc-box .cmc-list .cmc-link:hover, .component-menu-category.celebrity .cmc-container .cmc-box .cmc-list .cmc-link:hover {
  color: #540101 !important;
}

.br-header-menu-category {
  width: 940px;
  height: 200px;
  margin: 0 10px 10px;
  position: relative;
  overflow: hidden;
}

.br-header-menu-category .br-links {
  width: 356px;
  height: 43px;
  position: absolute;
  top: 137px;
  left: 21px;
}

.br-header-menu-category .br-links .br-link {
  color: #fff;
  float: left;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  border-right: 2px solid #fff;
  width: 107px;
  height: 43px;
  padding: 0 5px;
  font-family: tablet_gothic_condensed, arial, sans-serif;
  font-size: 15px;
  line-height: 50px;
  display: block;
  overflow: hidden;
}

.br-header-menu-category .br-links .br-link:last-child {
  border-right: none;
}

.br-header-category {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.br-header-category .br-box {
  background-color: #fff;
  min-width: 356px;
  max-width: 50%;
  height: 95px;
  position: absolute;
  top: 42px;
  left: 21px;
}

.br-header-category .br-box .br-logo {
  width: 22px;
  display: block;
  position: absolute;
  top: 19px;
  left: 20px;
}

.br-header-category .br-box .br-title {
  color: #16212d;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  height: 30px;
  margin: 20px 20px 0 61px;
  font-family: tablet_gothic_condensed, arial, sans-serif;
  font-size: 30px;
  line-height: 30px;
  top: 20px;
  left: 61px;
  overflow: hidden;
}

.br-header-category .br-box .br-title * {
  color: #16212d;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  display: block;
  overflow: hidden;
}

.br-header-category .br-box .br-subtitle {
  color: #96a2ae;
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  bottom: 16px;
  left: 61px;
}

.br-header-category .br-bg {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@font-face {
  font-family: opensans-regular;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: opensans-bold;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.cookie-bar {
  text-align: center;
  color: #fff;
  z-index: 200000;
  background-color: rgba(50, 50, 50, .95);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0;
  font-family: opensans-regular, arial, sans-serif;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.cookie-bar a, .cookie-bar a:hover {
  color: inherit;
}

.cookie-bar__title {
  font-family: opensans-bold, arial, sans-serif;
  font-size: 26px;
  line-height: 36px;
}

.cookie-bar__title br {
  display: none;
}

.cookie-bar__text {
  padding: 5px 0 10px;
  font-size: 12px;
  line-height: 16px;
}

.cookie-bar__text br {
  display: inline;
}

.cookie-bar__enable-btn {
  text-transform: uppercase;
  cursor: pointer;
  background-color: red;
  width: 140px;
  height: 35px;
  margin: 0 15px 10px auto;
  font-size: 14px;
  line-height: 35px;
  text-decoration: none;
  display: inline-block;
}

.cookie-bar__more-info {
  margin-bottom: 0;
  font-size: 11px;
  line-height: 15px;
  text-decoration: underline;
  display: inline-block;
}

.cookie-bar__more-info:hover {
  text-decoration: none;
}

.cookie-bar__close-btn {
  cursor: pointer;
  width: 14px;
  height: 14px;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
  overflow: hidden;
}

.cookie-bar__close-btn:before, .cookie-bar__close-btn:after {
  content: "";
  background: #fff;
  width: 100%;
  height: 2px;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  left: 0;
}

.cookie-bar__close-btn:before {
  transform: rotate(45deg);
}

.cookie-bar__close-btn:after {
  transform: rotate(-45deg);
}

.cookie-bar__close-btn--thick:before, .cookie-bar__close-btn--thick:after {
  height: 3px;
  margin-top: -2px;
}

